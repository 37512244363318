import { Link, useHistory } from "react-router-dom";
import { NotiContext, RulesContext } from "../store/keep";
import React, { useContext, useEffect, useState } from "react";
import { api, header_token, img_url } from "../config";

import $ from "jquery";
import Axios from "axios";
import { SearchBar } from "./SearchBar";
import { checkImageFlowOld } from '../utils/Function'
import moment from "moment";
import swal from "sweetalert";

export const Topbar = () => {
  const { push } = useHistory();
  const [load, setload] = useState(false);
  const [logo, setlogo] = useState("");
  const [admin_img, setadmin_img] = useState({});
  const { setrules } = useContext(RulesContext);
  const [is_read_all, setis_read_all] = useState(true);
  const [limit, setlimit] = useState(5);
  const [notifications, setnotifications] = useState([]);
  const { noti } = useContext(NotiContext);

  const logout = async () => {
    try {
      let { data } = await Axios.get(`${api}/user/logout`, header_token);
      // console.log(data);

      if (data.code === 200) {
        // console.log("check is login");

        localStorage.clear();

        window.location = "/";
      }
    } catch (error) {
      // console.log(error.response);
    }
  };

  const unsubscribeTopic = async () => {
    try {
      let unsub = await Axios.get(
        `${api}/admin/fcm/unsubscribe/${localStorage.getItem("fcm-token")}`,
        header_token
      );
      // console.log("unsub", unsub);

      if (unsub.status === 200) {
        return true;
      }
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  const getData = async () => {
    try {
      let { data } = await Axios.get(`${api}/information`);

      setlogo(img_url + data.logo);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getProfile = async () => {
    try {
      let { data } = await Axios.get(`${api}/user/profile`, header_token);

      setadmin_img(checkImageFlowOld(data.picture) ? img_url + data.picture : data.picture);
      localStorage.setItem("user_id", data.id);

      getRule(data.id);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getRule = async (id) => {
    try {
      let { data } = await Axios.get(`${api}/admin/rule/${id}`, header_token);
      // // console.log("data", data);

      let tmp = { ...data };
      delete tmp["id"];
      delete tmp["user_id"];
      // // console.log("tmp", tmp);
      setrules(tmp);
    } catch (error) {
      // console.log(error.response);
      setrules({
        banner: false,
        branch: false,
        deposit: false,
        member: false,
        news: false,
        order: false,
        promotion: false,
        recommend: false,
        rider: false,
        setting: false,
        shipping: false,
        summary: false,
        withdraw: false,
        car_service: false,
        car_service_setting: false,
      });
    }
  };

  // const getNoti = async () => {
  //   setload(true);
  //   try {
  //     let { data } = await Axios.get(
  //       `${api}/notification?page=${1}&limit=${limit}`,
  //       header_token
  //     );

  //     console.log(data);
  //     if (data.notifications) {
  //       setnotifications(data.notifications);
  //     } else {
  //       setnotifications([]);
  //     }
  //   } catch (error) {
  //     // console.log(error.response);
  //     swal(`${error.response.data.message}`, {
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  //   setload(false);
  // };

  // const getNotiBadge = async () => {
  //   try {
  //     let { data } = await Axios.get(`${api}/notification/badge`, header_token);

  //     // // console.log("abad", data);
  //     if (data.total > 0) {
  //       setis_read_all(false);
  //     }
  //   } catch (error) {
  //     // console.log(error.response);
  //     swal(`${error.response.data.message}`, {
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  // };

  // const readNoti = async (id) => {
  //   try {
  //     let { data } = await Axios.get(
  //       `${api}/notification/read/${id}`,
  //       header_token
  //     );
  //     // // console.log(data);
  //     if (data.code === 200) {
  //       getNoti();
  //       getNotiBadge();
  //     }
  //   } catch (error) {
  //     // console.log(error.response);
  //     swal(`${error.response.data.message}`, {
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  // };

  useEffect(() => {
    getData();
    getProfile();
    // getNotiBadge();
    // getNoti();

    $(".load-more").on("click", function (e) {
      e.stopPropagation();
      setlimit((prev) => (prev += 5));
    });
  }, []);

  // useEffect(() => {
  //   getNoti();
  // }, [limit, noti]);

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center">
        <Link className="navbar-brand brand-logo" to="/">
          <img src="/images/logo.png" alt="logo" width={50} />
        </Link>
        <Link
          className="navbar-brand brand-logo-mini"
          to="/"
          style={{ margin: 0 }}
        >
          <img
            src="/images/logo.png"
            alt="logo"
            width={50}
            style={{ height: "auto" }}
          />
        </Link>
      </div>

      <div className="navbar-menu-wrapper d-flex align-items-center ">
        <ul className="navbar-nav container-fullwidth sugg">
          <li style={{ width: "100%" }}>
            <SearchBar />
          </li>
        </ul>

        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown ">
            <a
              className="nav-link "
              href="#"
              data-toggle="dropdown"
              id="profileDropdown"
            >
              <img
                src={admin_img === "" ? "/images/no-img.jpg" : admin_img}
                alt="profile"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/no-img.jpg";
                }}
              // style={{ width: 40, height: 40 }}
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <Link to={`/admin_profile`} className="dropdown-item">
                <i className="mdi mdi-settings "></i>
                Settings
              </Link>
              <a
                className="dropdown-item"
                onClick={() => {
                  swal({
                    title: `ต้องการจะออกจากระบบหรือไม่ ?`,
                    confirmButtonText: "ตกลง",
                    cancelButtonText: "ยกเลิก",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willDelete) => {
                    if (willDelete) {
                      await unsubscribeTopic();
                      await logout();
                    }
                  });
                }}
              >
                <i className="mdi mdi-logout"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>

        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};
