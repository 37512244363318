import React from "react";

export const ModalShowImage = ({ img }) => {
  return (
    <div className="modal  show-img" role="dialog">
      <div className="modal-dialog ">
        <div className="modal-content" style={{ width: 800 }}>
          <div className="modal-header">
            <span>รูปภาพ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <img
              src={img ? img : ""}
              style={{ maxWidth: 600, display: "block", margin: "auto" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/no-img.jpg";
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
