import React, { useState } from "react";
import { api, header_token, img_url } from "../config";

import Autosuggest from "react-autosuggest";
import Axios from "axios";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import swal from "sweetalert";
export const SearchBar = (props) => {
  const [value, setvalue] = useState("");
  const [suggestions, setsuggestions] = useState([]);

  const check = /^[0-9a-zA-Zก-๙\\s]+$/;

  // const escapeRegexCharacters = (str) => {
  //   return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  // };

  const getSuggestionValue = (suggestion) => {
    return `${suggestion.full_name} `;
  };

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.full_name}`;
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);

    return (
      <span
        onClick={() => {
          window.location = suggestion.go;
        }}
        className={"suggestion-content "}
        style={{
          backgroundImage: `url(${
            suggestion.imageLink ? suggestion.imageLink : "/images/no-img.jpg"
          })`,
          backgroundSize: 50,
        }}
      >
        <span className="name">
          {parts.map((part, index) => {
            // console.log("parts", parts);
            const className = part.highlight ? "highlight" : null;
            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </span>
    );
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (check.test(value)) {
      await getData(value);
    }
  };

  const onSuggestionsClearRequested = () => {
    setsuggestions([]);
  };

  const getData = async (value) => {
    let send_data;
    let tmp = [];

    try {
      send_data = {
        name_th: value,
        // status: [0],
      };

      send_data = {
        search: value,
        status: -1,
        role: "rider",
      };

      let rider = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${10}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      rider.data.users.map((value) => {
        tmp.push({
          full_name: `${value.full_name} (พนักงานขับรถ)`,
          go: `/rider/history/${value.id}`,
          imageLink: img_url + value.picture,
        });
        return;
      });

      send_data = {
        // end_date: start,
        order_no: value,
        // start_date: start,
        // status: -1,
      };

      let orders = await Axios.post(
        `${api}/admin/order/filter?page=${1}&limit=${10}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("orders", orders);
      if (orders.data.orders !== null) {
        orders.data.orders.map((value) => {
          tmp.push({
            full_name: `${value.order_no} (ออเดอร์)`,
            go: `/order/detail/${value.id}`,
            imageLink: "",
          });
          return;
        });
      }

      setsuggestions(tmp);

      // setdata(data.users);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onChange = async (event, { newValue }) => {
    if (check.test(newValue) || newValue === "") {
      setvalue(newValue);
    }
  };

  const inputProps = {
    placeholder: "ค้นหา...",
    value,
    onChange: onChange,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
};
