import React from "react"
import { checkImageFlowOld } from '../../utils/Function'
import { img_url } from "../../config";

export const RiderDetailImage = ({
    image = "",
    onClick = () => { },
}) => {
    const isShowOldFlow = checkImageFlowOld(image)

    return <div
        className="thumbnail cursor-img"
        style={{ marginRight: '20px' }}
        data-toggle="modal"
        data-target=".show-img"
        onClick={onClick}
    >
        <img
            alt=""
            className="lazy img-responsive"
            src={
                image === ""
                    ? "/images/no-img.jpg"
                    : isShowOldFlow ? `${img_url}${image}` : image
            }
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/no-img.jpg";
            }}
        />
    </div>
}