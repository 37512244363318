export const convertPaidByText = (value) => {
    if (value.toLowerCase() === 'sender') {
        return 'ผู้รับ'
    } else {
        return 'ผู้ส่ง'
    }
}

export const checkImageFlowOld = (image) => {
    if (image === undefined) return ""

    return image.toString().indexOf('.com') === -1
}