import React, { useEffect, useState } from "react";
import Select from "react-select";
import swal from "sweetalert";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "react-loadingmask/dist/react-loadingmask.css";

import "moment/locale/th";
import { api, header_token } from "../../config";
import Axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

export const PromotionCodeAdd = () => {
  const { register, handleSubmit, errors, control, trigger } = useForm();
  const { push } = useHistory();

  const [type, settype] = useState("");
  const [discount_type, setdiscount_type] = useState("");
  const [condition, setcondition] = useState({
    value: 3,
    label: "ใช้ได้จำนวนครั้ง/วัน",
  });
  const [isquantity, setisquantity] = useState(true);
  const [begin_date, setbegin_date] = useState("");
  const [end_date, setend_date] = useState("");

  const addPromotionCode = async (send_data) => {
    try {
      // console.log(send_data);
      let res = await Axios.post(
        `${api}/admin/promotion-code`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 201) {
        swal("เพิ่มโปรโมชั่นโค้ดสำเร็จ !!", {
          icon: "success",
        });
        setTimeout(() => {
          push(`/promotion_code`);
        }, 1200);
      } else {
        swal("ผิดพลาด !!", {
          icon: "error",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onSubmit = async (data) => {
    // console.log('onSubmit',data);

    let begin = moment(begin_date, "DD/MM/YYYY").format("YYYY-MM-DD");
    let end = moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD");

    let send_data = {
      begin: `${begin} ${data.begin_time + ":00"}`,
      code: data.code.trim(),
      condition: parseInt(condition.value),
      condition_quantity: data.condition_quantity
        ? parseInt(data.condition_quantity)
        : 0,
      detail: data.detail.trim(),
      discount: parseInt(data.discount),
      discount_type: parseInt(discount_type.value),
      end: `${end} ${data.end_time + ":00"}`,
      max_discount: parseInt(data.max_discount),
      min_price: parseInt(data.min_price),
      name_en: "",
      name_th: data.name_th.trim(),
      quantity: parseInt(data.quantity),
      type: parseInt(type.value),
    };
    // console.log(send_data);
    addPromotionCode(send_data);
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label htmlFor="name_th" className="col-sm-3 col-form-label ">
                    ชื่อโปรโมชั่น
                  </label>
                  <div className="col-sm-4">
                    <input
                      name="name_th"
                      type="text"
                      className={`form-control ${
                        errors.name_th ? "error-input" : ""
                      }`}
                      ref={register({ required: true })}
                      placeholder="ชื่อ"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="type" className="col-sm-3 col-form-label ">
                    ส่วนลดสำหรับ
                  </label>
                  <div className="col-sm-4">
                    <Select
                      name="type"
                      value={type}
                      onChange={async (e) => {
                        await settype(e);
                        trigger("type");
                      }}
                      options={[{ value: 1, label: "ค่าส่ง" }]}
                      placeholder={"ส่วนลดสำหรับ..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.type ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.type ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      ref={(e) => {
                        register(
                          { name: "type" },
                          {
                            validate: () => {
                              return type.value ? true : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="code" className="col-sm-3 col-form-label ">
                    รหัสโค้ด
                  </label>
                  <div className="col-sm-4">
                    <input
                      name="code"
                      type="text"
                      className={`form-control ${
                        errors.code ? "error-input" : ""
                      }`}
                      ref={register({ required: true })}
                      placeholder="รหัส"
                      // value={personal_number}
                      // onChange={(e) => setpersonal_number(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="discount"
                    className="col-sm-3 col-form-label "
                  >
                    ส่วนลด
                  </label>
                  <div className="col-2">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.discount ? "error-input" : ""
                      }`}
                      id="discount"
                      name="discount"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="col-2">
                    <Select
                      name="discount_type"
                      value={discount_type}
                      onChange={async (e) => {
                        await setdiscount_type(e);
                        trigger("discount_type");
                      }}
                      options={[
                        { value: "0", label: "%" },
                        { value: 1, label: "บาท" },
                      ]}
                      placeholder={"ชนิด..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.discount_type ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.discount_type
                              ? "red"
                              : "#cdd4e0",
                          },
                        }),
                      }}
                      ref={(e) => {
                        register(
                          { name: "discount_type" },
                          {
                            validate: () => {
                              return discount_type.value ? true : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="min_price"
                    className="col-sm-3 col-form-label "
                  >
                    สั่งซื้อขั้นต่ำ(บาท)
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.min_price ? "error-input" : ""
                      }`}
                      id="min_price"
                      name="min_price"
                      ref={register({ required: true })}
                      placeholder="ขั้นต่ำ"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="max_discount"
                    className="col-sm-3 col-form-label "
                  >
                    ลดสูงสุด(บาท)
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.max_discount ? "error-input" : ""
                      }`}
                      id="max_discount"
                      name="max_discount"
                      ref={register({ required: true })}
                      placeholder="สูงสุด"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="condition"
                    className="col-sm-3 col-form-label "
                  >
                    เงื่อนไขการใช้
                  </label>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-8">
                        <Select
                          name="condition"
                          value={condition}
                          onChange={async (e) => {
                            if (e.value === 3 || e.value === 2) {
                              setisquantity(true);
                            } else {
                              setisquantity(false);
                            }
                            await setcondition(e);
                            trigger("condition");
                          }}
                          options={[
                            { value: 3, label: "ใช้ได้...จำนวนครั้ง/วัน" },
                            { value: 2, label: "ใช้ได้...ครั้งเท่านั้น/ท่าน" },
                            { value: "0", label: "ไม่จำกัด" },
                            { value: 1, label: "สั่งซื้อครั้งแรก" },
                          ]}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: errors.condition ? "red" : "#cdd4e0",
                              "&:hover": {
                                borderColor: errors.condition
                                  ? "red"
                                  : "#cdd4e0",
                              },
                            }),
                          }}
                          ref={(e) => {
                            register(
                              { name: "condition" },
                              {
                                validate: () => {
                                  return condition.value ? true : false;
                                },
                              }
                            );
                          }}
                        />
                      </div>

                      <div className="col-4">
                        {isquantity ? (
                          <input
                            type="text"
                            className={`form-control ${
                              errors.condition_quantity ? "error-input" : ""
                            }`}
                            id="condition_quantity"
                            name="condition_quantity"
                            ref={register({
                              required: isquantity ? true : false,
                            })}
                            placeholder="ครั้ง"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="begin_date">วันเริ่มต้น</label>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-7">
                        <Controller
                          control={control}
                          name="begin_date"
                          render={({ onChange, value }) => (
                            <DayPickerInput
                              value={begin_date}
                              onDayChange={async (e) => {
                                await setbegin_date(e);
                                trigger("begin_date");

                                if (!end_date) {
                                  await setend_date(e);
                                  trigger("end_date");
                                }
                              }}
                              placeholder={"วัน/เดือน/ปี"}
                              style={{ width: "100%" }}
                              inputProps={{
                                style: {
                                  width: "100%",
                                  padding: 8,
                                  border: "1px solid #cdd4e0",
                                  borderRadius: 5,
                                  borderColor: errors.begin_date
                                    ? "red"
                                    : "#cdd4e0",
                                  height: "100%",
                                },
                              }}
                              format="L"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: "th",
                                localeUtils: MomentLocaleUtils,
                              }}
                              ref={(e) => {
                                register(
                                  { name: "begin_date" },
                                  {
                                    validate: (value) => {
                                      return begin_date ? true : false;
                                    },
                                  }
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          type="time"
                          min="00:00"
                          max="60:00"
                          className={`form-control ${
                            errors.begin_time ? "error-input" : ""
                          }`}
                          id="begin_time"
                          name="begin_time"
                          ref={register({ required: true })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="end_date">วันสิ้นสุด</label>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-7">
                        <Controller
                          control={control}
                          name="end_date"
                          render={({ onChange, value }) => (
                            <DayPickerInput
                              value={end_date}
                              onDayChange={async (e) => {
                                await setend_date(e);
                                trigger("end_date");

                                if (!end_date) {
                                  await setbegin_date(e);
                                  trigger("begin_date");
                                }
                              }}
                              placeholder={"วัน/เดือน/ปี"}
                              style={{ width: "100%" }}
                              inputProps={{
                                style: {
                                  width: "100%",
                                  padding: 8,
                                  border: "1px solid #cdd4e0",
                                  borderRadius: 5,
                                  borderColor: errors.end_date
                                    ? "red"
                                    : "#cdd4e0",
                                  height: "100%",
                                },
                              }}
                              format="L"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: "th",
                                localeUtils: MomentLocaleUtils,
                              }}
                              ref={(e) => {
                                register(
                                  { name: "end_date" },
                                  {
                                    validate: (value) => {
                                      return end_date ? true : false;
                                    },
                                  }
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          type="time"
                          min="00:00"
                          max="60:00"
                          className={`form-control ${
                            errors.end_time ? "error-input" : ""
                          }`}
                          id="end_time"
                          name="end_time"
                          ref={register({ required: true })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="quantity"
                    className="col-sm-3 col-form-label "
                  >
                    จำนวน
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.quantity ? "error-input" : ""
                      }`}
                      id="quantity"
                      name="quantity"
                      ref={register({ required: true, maxLength: 7 })}
                      placeholder="จำนวน"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="detail" className="col-sm-3 col-form-label ">
                    หมายเหตุ
                  </label>
                  <div className="col-sm-4">
                    <textarea
                      className={`form-control ${
                        errors.detail ? "error-input" : ""
                      }`}
                      name="detail"
                      style={{ height: 100 }}
                      ref={register({ required: true, maxLength: 400 })}
                    ></textarea>
                  </div>
                </div>

                <button className="btn btn-primary btn-sm mr-3 d-none d-md-block ml-auto">
                  บันทึก
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
