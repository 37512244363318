import "react-loadingmask/dist/react-loadingmask.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { NotiContext } from "../../store/keep";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertPaidByText } from "../../utils/Function"
import moment from "moment";

export const OrderCarSuccess = (props) => {
  const { start_date, optionArea, statusOrder } = props;
  const { noti } = useContext(NotiContext);

  const checkAllRef = useRef(null);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [loading, setloading] = useState(false);
  const [is_first, setis_first] = useState(true);
  const [total_count, settotal_count] = useState(0);

  const [id, setid] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [option_area, setoption_area] = useState([]);
  const [filter_area, setfilter_area] = useState("");

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: start,
        order_no: id ? id : "",
        area_id: filter_area ? filter_area.value : "",
        service: ["car"],
        status: statusOrder,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);
        setdata(data.orders);
      } else {
        setdata([]);
        settotal_count(0);
      }
      setis_first(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setloading(false);
  };

  useEffect(() => {
    if (is_first) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (!is_first) {
      console.log("start");
      getData();
    }
  }, [start_date]);

  useEffect(() => {
    if (!is_first) {
      setcurrent_page(1);
      getData();
    }
  }, [filter_area, id, filter_name]);

  useEffect(() => {
    if (!is_first) {
      getData();
    }
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "car_service") {
      getData();
    }
  }, [noti]);

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">Order Id</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ID"
                      value={id}
                      onChange={(e) => setid(e.target.value)}
                    />
                  </div>

                  <div className="mr-1" style={{ width: 150 }}>
                    <span className="tx-13 text-muted">พื้นที่</span>
                    <Select
                      value={filter_area}
                      onChange={(e) => {
                        setfilter_area(e);
                      }}
                      options={optionArea}
                      placeholder={"พื้นที่.."}
                    />
                  </div>

                  {/* <div className="col ml-1">
                    <div className="row">
                      <div style={{ width: 120 }} className="">
                        <span className="tx-13 text-muted">ดำเนินการ</span>
                        <Select
                          // styles={{border: '1px solid #cdd4e0'}}
                          value={filter_order}
                          onChange={async (e) => {
                            setfilter_order(e);
                          }}
                          options={option_order}
                          placeholder={"เลือก..."}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: 120 }}>
                    <button
                      id="search"
                      className="btn btn-primary ml-3"
                      style={{ marginTop: 20 }}
                    >
                      จัดส่ง
                    </button>
                  </div> */}
                </div>
              </div>
              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table id="dataTable" className="table sticky-table">
                      <thead>
                        <tr>
                          <th style={{ width: 30 }} className="sticky-head">
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, index) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });

                                setdata(tmp);
                              }}
                            />
                          </th>
                          <th style={{ width: 150 }} className="sticky-head">
                            Order No.
                          </th>
                          <th style={{ width: 120 }}>เวลา</th>
                          <th style={{ width: 150 }}>การจัดส่ง</th>
                          <th style={{ width: 150 }}>บริการ</th>
                          <th style={{ width: 300 }}>ต้นทาง</th>
                          <th style={{ width: 300 }}>ปลายทาง</th>
                          <th style={{ width: 150 }}>ผู้รับงาน</th>
                          <th style={{ width: 80 }}>ค่าขนส่ง</th>
                          <th style={{ width: 120 }}>ค่าบริการเสริม</th>
                          <th style={{ width: 150 }}>โปรโมชั่น</th>
                          <th style={{ width: 80 }}>รวม</th>
                          <th style={{ width: 120 }}>ระยะทาง</th>
                          <th style={{ width: 150 }}>สถานะ</th>
                          <th style={{ width: 120 }}>ชำระ</th>
                          <th style={{ width: 120 }}>ผู้ชำระ</th>
                          <th style={{ width: 150 }}>รายละเอียด</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked
                                  ? "#e8eaed "
                                  : "",
                              }}
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td
                                className="sticky-col"
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                              >
                                <a
                                  href="#"
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/car_service_order/detail/${value.id}`
                                    )
                                  }
                                >
                                  {value.order_no}
                                </a>
                              </td>
                              <td>
                                <i className="fa fa-clock-o mr-1"></i>
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>
                                {value.type === 0 ? (
                                  <span className="badge badge-outline-warning badge-pill">
                                    {moment(value.due_date).format(
                                      "DD/MM/YYYY"
                                    )}{" "}
                                    {moment(value.due_date).format("LT")}
                                  </span>
                                ) : (
                                  <span className="badge badge-outline-success badge-pill">
                                    สังทันที
                                  </span>
                                )}
                              </td>

                              <td>{value.car_service_name}</td>
                              <td>
                                {value.car_service_start_point.address}
                                <br />
                                {value.phone_number}
                              </td>
                              <td>
                                {value.car_service_destinations
                                  ? value.car_service_destinations.map(
                                      (v, inx) => (
                                        <div key={inx}>
                                          {value.car_service_destinations
                                            .length !== 1
                                            ? `(${inx + 1})`
                                            : null}{" "}
                                          {v.address}
                                        </div>
                                      )
                                    )
                                  : null}
                              </td>
                              <td>
                                {value.user.full_name}
                                <br />
                                {value.user.phone_number}
                              </td>
                              <td>฿{value.car_service_cost}</td>
                              <td>฿{value.car_service_addon_cost}</td>
                              <td>
                                {value.promotion_discount > 0 ? (
                                  <span>
                                    {value.promotion_code
                                      ? value.promotion_code
                                      : ""}{" "}
                                    {value.promotion_discount
                                      ? `(฿${value.promotion_discount.toLocaleString()})`
                                      : "0"}
                                  </span>
                                ) : null}
                              </td>
                              <td>฿{value.total}</td>
                              <td>~ {value.distance}km</td>
                              <td>
                                {(() => {
                                  if (value.type === 0)
                                    return "ออเดอร์ล่วงหน้า";

                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "ออเดอร์ใหม่";
                                    case 2:
                                      return "พนักงานรับออเดอร์";
                                    case 3:
                                      return "ดำเนินการ";
                                    case 4:
                                      return "กำลังจัดส่ง";
                                    case 5:
                                      return "ส่งสำเร็จ";
                                    case 6:
                                      return "ส่งไม่สำเร็จ";

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>{value.payment_method}</td>
                              <td>{convertPaidByText(value.paid_by)}</td>
                              <td>
                                <a
                                  href="#"
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/car_service_order/detail/${value.id}`
                                    )
                                  }
                                  className="btn btn-outline-primary btn-sm"
                                >
                                  รายละเอียด
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={page_count}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
