import React, { useEffect, useState } from "react";
import { api, header_token, img_url } from "../../config";

import Axios from "axios";
import { checkImageFlowOld } from '../../utils/Function'
import swal from "sweetalert";
import { useParams } from "react-router-dom";

export const RiderDetailHead = (props) => {
  const { id } = useParams();

  const [data, setdata] = useState({});
  const [photo, setphoto] = useState("");
  const getData = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/user/profile/${id}`,
        header_token
      );
      // console.log("getdata xxx", data,data.picture);
      setdata(data);
      setphoto(checkImageFlowOld(data.picture) ? img_url + data.picture : data.picture);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataWallets = async (type) => {
    try {
      let send_data = {
        is_user: -1,
        status: -1,
        type: type,
        user_id: id,
      };
      // console.log(send_data);

      let { data } = await Axios.post(
        `${api}/admin/wallet/history?page=${props.paginate.current_page}&limit=${props.paginate.limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      props.callback(data.wallets, data.meta.total_count);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    // console.log(props);
    getData();
    if (props.tabType) {
      if (props.tabType === "credit") {
        getDataWallets(1);
      }
      if (props.tabType === "withdraw") {
        getDataWallets(2);
      }
    }
  }, []);

  useEffect(() => {
    // if (props.tabType) {
    if (props.tabType === "credit") {
      getDataWallets(1);
    }
    if (props.tabType === "withdraw") {
      getDataWallets(2);
    }
    // }
  }, [props.paginate]);
  return (
    <div className="row mb-3">
      <div className="thumbnail ml-2">
        <img
          className="lazy img-responsive"
          src={photo === "" ? "/images/no-img.jpg" : `${photo}`}
        />
      </div>

      <div className="ml-2">
        <h3>{data.full_name}</h3>
        <h6>รหัสพนักงาน {data.user_id}</h6>
      </div>
    </div>
  );
};
