import "react-day-picker/lib/style.css";
import "moment/locale/th";

// import { APIProvider, AdvancedMarker, InfoWindow, Map, Marker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { Doughnut, Line } from "react-chartjs-2";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useState } from "react";
import { api, convertMoney, google_api_key, header_token, maps_id, socket_api } from "../config";

import Axios from "axios";
import { CustomMarker } from './Maps/CustomMarker'
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import swal from "sweetalert";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const Dashboard = () => {
  const [start_date, setstart_date] = useState(moment().format("DD/MM/YYYY"));
  const [drivers, setDrivers] = useState([])

  const [doughnut_data, setdoughnut_data] = useState({
    labels: [
      "ออเดอร์ใหม่(0%)",
      "กำลังจัดส่ง (0%)",
      "ส่งเรียบร้อย(0%)",
      "ยกเลิก (0%)",
    ],
    datasets: [
      {
        data: [150, 50, 100, 159],
        backgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
        hoverBackgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
      },
    ],
  });
  const [line_data, setline_data] = useState({
    labels: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
      // "24:00",
    ],
    datasets: [
      {
        label: "ยอดขาย",
        fill: true,
        lineTension: 0,
        backgroundColor: "#f2eef1",
        borderColor: "#5b2d51",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#5b2d51",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#5b2d51",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [],
      },
      {
        label: "ออเดอร์",
        fill: true,
        lineTension: 0,
        backgroundColor: "#f2e5e9",
        borderColor: "#eb647d",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#eb647d",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#eb647d",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [],
      },
    ],
  });

  const [count_user, setcount_user] = useState(0);
  const [count_rider, setcount_rider] = useState(0);
  const [count_area, setcount_area] = useState(0);
  const [count_order, setcount_order] = useState(0);
  const [sum_sale, setsum_sale] = useState(0);
  const [top] = useState("top");

  const getDataUser = async () => {
    try {
      let send_data = {
        // area_id: filter_area.value,
        // employee_type_id: filter_type.value,
        // full_name: filter_name,
        status: -1,
        // team_id: filter_team.value,
        role: "user",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${1}`,
        send_data,
        header_token
      );
      setcount_user(data.meta.total_count);
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataRider = async () => {
    try {
      let send_data = {
        // area_id: filter_area.value,
        // employee_type_id: filter_type.value,
        // full_name: filter_name,
        status: -1,
        // team_id: filter_team.value,
        role: "rider",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${1}`,
        send_data,
        header_token
      );
      setcount_rider(data.meta.total_count);
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      setcount_area(data.length);
    } catch (error) {
      // console.log(error);
    }
  };

  const getDataOrder = async () => {
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        end_date: start,
        order_no: "",
        start_date: start,
        // status: -1,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${1}&limit=${99999999}&sort=${-1}`,
        send_data,
        header_token
      );

      // // console.log("data", data);
      if (data.orders !== null) {
        let total_order = data.meta.total_count;
        let new_count = data.orders.filter((e) => e.status === 1).length;
        let sending_count = data.orders.filter((e) => e.status === 4).length;
        let success_count = data.orders.filter((e) => e.status === 5).length;
        let cancel_count = data.orders.filter((e) => e.status === 0).length;
        let sum = 0;
        let count = 0;
        data.orders.map((value, idx) => {
          if (value.status === 5) {
            sum += value.total;
            count++;
          }
        });

        plotGraph(data.orders.filter((v) => v.status === 5));

        setsum_sale(sum);
        let percentage_new = (new_count / total_order) * 100;
        let percentage_sending = (sending_count / total_order) * 100;
        let percentage_success = (success_count / total_order) * 100;
        let percentage_cancel = (cancel_count / total_order) * 100;

        setdoughnut_data({
          labels: [
            `ออเดอร์ใหม่(${percentage_new.toLocaleString()}%)`,
            `กำลังจัดส่ง (${percentage_sending.toLocaleString()}%)`,
            `ส่งเรียบร้อย(${percentage_success.toLocaleString()}%)`,
            `ยกเลิก (${percentage_cancel.toLocaleString()}%)`,
          ],
          datasets: [
            {
              data: [
                data.orders.filter((e) => e.status === 1).length,
                data.orders.filter((e) => e.status === 4).length,
                data.orders.filter((e) => e.status === 5).length,
                data.orders.filter((e) => e.status === 0).length,
              ],
              backgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
              hoverBackgroundColor: [
                "#9d00cc",
                "#7571f9",
                "#58d8a3",
                "#434a54",
              ],
            },
          ],
        });
        setcount_order(count);
      } else {
        setsum_sale(0);

        setdoughnut_data({
          labels: [
            `ออเดอร์ใหม่(${0}%)`,
            `กำลังจัดส่ง (${0}%)`,
            `ส่งเรียบร้อย(${0}%)`,
            `ยกเลิก (${0}%)`,
          ],
          datasets: [
            {
              data: [1, 1, 1, 1],
              backgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
              hoverBackgroundColor: [
                "#9d00cc",
                "#7571f9",
                "#58d8a3",
                "#434a54",
              ],
            },
          ],
        });
        setline_data({
          labels: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            // "24:00",
          ],
          datasets: [
            {
              label: "ยอดขาย",
              fill: true,
              lineTension: 0,
              backgroundColor: "#f2eef1",
              borderColor: "#5b2d51",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#5b2d51",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#5b2d51",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: [],
            },
            {
              label: "ออเดอร์",
              fill: true,
              lineTension: 0,
              backgroundColor: "#f2e5e9",
              borderColor: "#eb647d",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#eb647d",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#eb647d",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: [],
            },
          ],
        });
        setcount_order(0);
      }
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const plotGraph = (orders) => {
    // // console.log(orders);
    let sales = {
      time_0: 0,
      time_1: 0,
      time_2: 0,
      time_3: 0,
      time_4: 0,
      time_5: 0,
      time_6: 0,
      time_7: 0,
      time_8: 0,
      time_9: 0,
      time_10: 0,
      time_11: 0,
      time_12: 0,
      time_13: 0,
      time_14: 0,
      time_15: 0,
      time_16: 0,
      time_17: 0,
      time_18: 0,
      time_19: 0,
      time_20: 0,
      time_21: 0,
      time_22: 0,
      time_23: 0,
    };
    let count = {
      time_0: 0,
      time_1: 0,
      time_2: 0,
      time_3: 0,
      time_4: 0,
      time_5: 0,
      time_6: 0,
      time_7: 0,
      time_8: 0,
      time_9: 0,
      time_10: 0,
      time_11: 0,
      time_12: 0,
      time_13: 0,
      time_14: 0,
      time_15: 0,
      time_16: 0,
      time_17: 0,
      time_18: 0,
      time_19: 0,
      time_20: 0,
      time_21: 0,
      time_22: 0,
      time_23: 0,
    };

    // let today = moment(start_date).format("YYYY-MM-DD");
    let today = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");

    let time_0 = [moment(today + " 00:00:00"), moment(today + " 00:59:59")];
    let time_1 = [moment(today + " 01:00:00"), moment(today + " 01:59:59")];
    let time_2 = [moment(today + " 02:00:00"), moment(today + " 02:59:59")];
    let time_3 = [moment(today + " 03:00:00"), moment(today + " 03:59:59")];
    let time_4 = [moment(today + " 04:00:00"), moment(today + " 04:59:59")];
    let time_5 = [moment(today + " 05:00:00"), moment(today + " 05:59:59")];
    let time_6 = [moment(today + " 06:00:00"), moment(today + " 06:59:59")];
    let time_7 = [moment(today + " 07:00:00"), moment(today + " 07:59:59")];
    let time_8 = [moment(today + " 08:00:00"), moment(today + " 08:59:59")];
    let time_9 = [moment(today + " 09:00:00"), moment(today + " 09:59:59")];
    let time_10 = [moment(today + " 10:00:00"), moment(today + " 10:59:59")];
    let time_11 = [moment(today + " 11:00:00"), moment(today + " 11:59:59")];
    let time_12 = [moment(today + " 12:00:00"), moment(today + " 12:59:59")];
    let time_13 = [moment(today + " 13:00:00"), moment(today + " 13:59:59")];
    let time_14 = [moment(today + " 14:00:00"), moment(today + " 14:59:59")];
    let time_15 = [moment(today + " 15:00:00"), moment(today + " 15:59:59")];
    let time_16 = [moment(today + " 16:00:00"), moment(today + " 16:59:59")];
    let time_17 = [moment(today + " 17:00:00"), moment(today + " 17:59:59")];
    let time_18 = [moment(today + " 18:00:00"), moment(today + " 18:59:59")];
    let time_19 = [moment(today + " 19:00:00"), moment(today + " 19:59:59")];
    let time_20 = [moment(today + " 20:00:00"), moment(today + " 20:59:59")];
    let time_21 = [moment(today + " 21:00:00"), moment(today + " 21:59:59")];
    let time_22 = [moment(today + " 22:00:00"), moment(today + " 22:59:59")];
    let time_23 = [moment(today + " 23:00:00"), moment(today + " 23:59:59")];

    orders.forEach((value) => {
      let time = moment(value.created_at, "YYYY-MM-DD h:mm:ss");

      if (1 === 1) {
        if (time.isBetween(time_0[0], time_0[1])) {
          sales.time_0 += value.total;
          count.time_0++;
        }

        if (time.isBetween(time_1[0], time_1[1])) {
          sales.time_1 += value.total;
          count.time_1++;
        }

        if (time.isBetween(time_2[0], time_2[1])) {
          sales.time_2 += value.total;
          count.time_2++;
        }

        if (time.isBetween(time_3[0], time_3[1])) {
          sales.time_3 += value.total;
          count.time_3++;
        }

        if (time.isBetween(time_4[0], time_4[1])) {
          sales.time_4 += value.total;
          count.time_4++;
        }

        if (time.isBetween(time_5[0], time_5[1])) {
          sales.time_5 += value.total;
          count.time_5++;
        }

        if (time.isBetween(time_6[0], time_6[1])) {
          sales.time_6 += value.total;
          count.time_6++;
        }

        if (time.isBetween(time_7[0], time_7[1])) {
          sales.time_7 += value.total;
          count.time_7++;
        }

        if (time.isBetween(time_8[0], time_8[1])) {
          sales.time_8 += value.total;
          count.time_8++;
        }

        if (time.isBetween(time_9[0], time_9[1])) {
          sales.time_9 += value.total;
          count.time_9++;
        }

        if (time.isBetween(time_10[0], time_10[1])) {
          sales.time_10 += value.total;
          count.time_10++;
        }

        if (time.isBetween(time_11[0], time_11[1])) {
          sales.time_11 += value.total;
          count.time_11++;
        }

        if (time.isBetween(time_12[0], time_12[1])) {
          sales.time_12 += value.total;
          count.time_12++;
        }

        if (time.isBetween(time_13[0], time_13[1])) {
          sales.time_13 += value.total;
          count.time_13++;
        }

        if (time.isBetween(time_14[0], time_14[1])) {
          sales.time_14 += value.total;
          count.time_14++;
        }

        if (time.isBetween(time_15[0], time_15[1])) {
          sales.time_15 += value.total;
          count.time_15++;
        }

        if (time.isBetween(time_16[0], time_16[1])) {
          sales.time_16 += value.total;
          count.time_16++;
        }
        if (time.isBetween(time_17[0], time_17[1])) {
          sales.time_17 += value.total;
          count.time_17++;
        }
        if (time.isBetween(time_18[0], time_18[1])) {
          sales.time_18 += value.total;
          count.time_18++;
        }
        if (time.isBetween(time_19[0], time_19[1])) {
          sales.time_19 += value.total;
          count.time_19++;
        }
        if (time.isBetween(time_20[0], time_20[1])) {
          sales.time_20 += value.total;
          count.time_20++;
        }
        if (time.isBetween(time_21[0], time_21[1])) {
          sales.time_21 += value.total;
          count.time_21++;
        }
        if (time.isBetween(time_22[0], time_22[1])) {
          sales.time_22 += value.total;
          count.time_22++;
        }
        if (time.isBetween(time_23[0], time_23[1])) {
          sales.time_23 += value.total;
          count.time_23++;
        }
      }
    });

    let datasetsCalc = [
      {
        label: "ยอดขาย",
        fill: true,
        lineTension: 0,
        backgroundColor: "#f2eef1",
        borderColor: "#5b2d51",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#5b2d51",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#5b2d51",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          sales.time_0,
          sales.time_1,
          sales.time_2,
          sales.time_3,
          sales.time_4,
          sales.time_5,
          sales.time_6,
          sales.time_7,
          sales.time_8,
          sales.time_9,
          sales.time_10,
          sales.time_11,
          sales.time_12,
          sales.time_13,
          sales.time_14,
          sales.time_15,
          sales.time_16,
          sales.time_17,
          sales.time_18,
          sales.time_19,
          sales.time_20,
          sales.time_21,
          sales.time_22,
          sales.time_23,
        ],
      },
      {
        label: "ออเดอร์",
        fill: true,
        lineTension: 0,
        backgroundColor: "#f2e5e9",
        borderColor: "#eb647d",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#eb647d",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#eb647d",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          count.time_0,
          count.time_1,
          count.time_2,
          count.time_3,
          count.time_4,
          count.time_5,
          count.time_6,
          count.time_7,
          count.time_8,
          count.time_9,
          count.time_10,
          count.time_11,
          count.time_12,
          count.time_13,
          count.time_14,
          count.time_15,
          count.time_16,
          count.time_17,
          count.time_18,
          count.time_19,
          count.time_20,
          count.time_21,
          count.time_22,
          count.time_23,
        ],
      },
    ];

    setline_data((prev) => ({
      ...prev,
      datasets: datasetsCalc,
    }));

    // // console.log(sales, count);
  };

  const initSocket = () => {
    // console.log('token: ', localStorage.getItem("token"))
    const socket = new WebSocket(socket_api + '?token=' + localStorage.getItem("token"));
    // Connection opened
    socket.addEventListener("open", (event) => {
      console.log('open socket')
      socket.send('{}')
      console.log('send msg done')
    })

    // Listen for messages
    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data)
      if (data['action'] == undefined) {
        setDrivers((prevMessages) => {
          if (data.role.name_en === 'user' || data.status !== 1 || (data.latitude === 0 && data.longitude === 0)) return prevMessages
          const dup = prevMessages.findIndex((val) => val.user_id === data.user_id);
          if (dup > -1) {
            prevMessages[dup].latitude = data.latitude
            prevMessages[dup].longitude = data.longitude

            return prevMessages
          }

          return [...prevMessages, data]
        });
      } else {
        console.log('action: => ', data["action"])
      }
    })

    socket.addEventListener("close", (event) => {
      console.log("socket close!!")
      console.log(event)
    })

    return () => {
      socket.close()
    };
  }

  useEffect(() => {
    // initSocket()
    getDataUser();
    getDataRider();
    getDataArea();
    getDataOrder();
  }, []);

  useEffect(() => {
    getDataOrder();
  }, [start_date]);

  const defaultLocation = { lat: 13.75398, lng: 100.50144 }

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap pb-2 mb-2">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold">หน้าหลัก</h4>
            <div className="d-none d-md-flex ml-2 mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">Home</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">Dashboard</p>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <span className="mt-2">
            <h5 className="font-weight-bold mt ml-2">
              {/* วันพุธที่ 6 พฤศจิกายน 2562 */}
              {moment().format("LL")}
            </h5>
          </span>

          <span className="ml-auto mr-3" style={{ width: 350 }}>
            <form action="/web/">
              <div className="row">
                <div className="col-auto ml-auto">
                  <DayPickerInput
                    value={start_date}
                    onDayChange={(e) => {
                      setstart_date(e);
                    }}
                    overlayComponent={CustomOverlay}
                    placeholder={"วัน/เดือน/ปี"}
                    format="L"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      locale: "th",
                      localeUtils: MomentLocaleUtils,
                    }}
                  />
                </div>
              </div>
            </form>
          </span>
        </div>

        <div className="row">
          <div className="col-md-6 col-xl-4 stretch-card grid-margin">
            <div className="card card_Hightlight">
              <div className="card-body">
                <div>
                  <h6 className="card-title tx-13  text-muted">
                    สมาชิกทั้งหมด
                  </h6>
                  <span className="h1 mr-2 ">{convertMoney(count_user)}</span>
                  <span>User</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-4 stretch-card grid-margin">
            <div className="card card_Hightlight">
              <div className="card-body">
                <h6 className="card-title tx-13  text-muted">
                  พนักงานส่งทั้งหมด
                </h6>
                <span className="h1 mr-2 ">{convertMoney(count_rider)}</span>
                <span>คน</span>
                <div className="row"></div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-4 stretch-card grid-margin">
            <div className="card card_Hightlight">
              <div className="card-body">
                <h6 className="card-title tx-13 text-muted">
                  พื้นที่ให้บริการ
                </h6>
                <span className="h1 mr-2 ">{convertMoney(count_area)}</span>
                <span className="tx-13 text-muted">พื้นที่</span>
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-xl-8 ">
            <div className="card">
              <div className="card-body">
                <div className="row mb-4">
                  <h4 className="ml-2 tx-13  text-muted">ยอดขาย</h4>
                </div>
                <div className="d-flex flex-wrap mb-4">
                  <div className="mr-4">
                    <h2 className="mb-1 font-weight-medium">
                      ฿{convertMoney(sum_sale)}
                    </h2>
                    <p className="text-muted mb-0 tx-12">ยอดขาย</p>
                  </div>
                  <div className="mr-4">
                    <h2 className="mb-1 font-weight-medium">
                      {convertMoney(count_order)}
                    </h2>
                    <p className="text-muted mb-0 tx-12">ออเดอร์</p>
                  </div>
                </div>
                <Line
                  data={line_data}
                  height={98}
                  options={{
                    legend: { position: top, fullWidth: true },
                  }}
                />
                {/* <div className="visitors-chart-wrapper">
                  <Line
                    data={line_data}
                    height={"99%"}
                    legend={{ position: top, fullWidth: true, rtl: true }}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-xl-4 ">
            <div className="card mb-4">
              <div className="card-body" style={{ height: 150 }}>
                <h4 className="mb-3 tx-13  text-muted">รายการวันนี้</h4>
                <div className="row">
                  <div className="col-6">
                    <p>ยอดขาย</p>
                    <span className="h2 mr-2 ">฿{convertMoney(sum_sale)}</span>
                  </div>
                  <div className="col-6">
                    <p>จำนวนออเดอร์</p>
                    <span className="h2 mr-2 ">
                      {convertMoney(count_order)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h4 className="mb-4 tx-13  text-muted">สถานะการจัดส่ง</h4>
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-12">
                    <Doughnut
                      data={doughnut_data}
                      legend={{
                        position: "right",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row"></div>
      </div>
      {/* <APIProvider apiKey={google_api_key}>
        <Map
          style={{ width: '100vw', height: '100vh' }}
          defaultCenter={defaultLocation}
          defaultZoom={11}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          mapId={maps_id}
        >
          {
            drivers.map((driver, index) => {
              if (
                (driver.latitude === 0 && driver.longitude === 0)
                || (driver.latitude === undefined && driver.longitude === undefined)
              ) return null

              return <CustomMarker key={index} driver={driver} />
            })
          }
        </Map>
      </APIProvider> */}
    </div>
  );
};
