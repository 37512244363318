import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";

import { EditorState, convertToRaw } from "draft-js";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginTop: -340, backgroundColor: "red" }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}
export const NewsAdd = () => {
  const { push } = useHistory();
  const { register, handleSubmit, errors, trigger } = useForm();

  const [detail, setdetail] = useState(EditorState.createEmpty());
  const [cover, setcover] = useState([]);
  const [effective_day, seteffective_day] = useState(new Date());
  const [effective_time, seteffective_time] = useState("00:00");
  const [note, setnote] = useState("");
  const [send_to, setsend_to] = useState([]);

  const uploadImageCallBack = async (file) => {
    let uploadedImages = cover;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
      base64: await toBase64(file),
    };

    // console.log(imageObject);
    uploadedImages.push(imageObject);

    // setcover((prev) => [...prev, uploadedImages]);
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.base64 } });
    });
  };

  const onEditorStateChange = (editorState) => {
    setdetail(editorState);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const addNews = async (send_data) => {
    try {
      // console.log(send_data);
      let res = await Axios.post(`${api}/admin/news`, send_data, header_token);

      // console.log(res);
      if (res.status === 201) {
        swal("เพิ่มประกาศข่าวเสร็จสิ้น !!", {
          icon: "success",
        });
        setTimeout(() => {
          push(`/news`);
        }, 1500);
      } else {
        swal("ผิดพลาด !!", {
          icon: "error",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onSubmit = (data) => {
    // console.log(send_to);
    let ef = moment(effective_day, "DD/MM/YYYY").format("YYYY-MM-DD");

    let send_ = {
      cover: cover.base64,
      description: draftToHtml(convertToRaw(detail.getCurrentContent())),
      effective_date: `${ef} ${effective_time + ":00"}`,
      note: note,
      for_roles: send_to.map((e) => e.value),
      title: data.title,
      status: 2,
    };

    addNews(send_);
    // console.log(send_);
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="dashboard-header d-flex flex-column grid-margin">
          <div className="d-flex align-items-center justify-content-between flex-wrap ">
            <div className="d-flex align-items-center">
              <h4 className="mb-0 font-weight-bold mr-2">ประกาศข่าว</h4>
              <div className="d-none d-md-flex mt-1">
                <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
                <i className="mdi mdi-chevron-right text-muted"></i>
                <p className="text-muted mb-0 tx-13 cursor-pointer">
                  ประกาศข่าว
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group row">
                    <label
                      htmlFor="title-html"
                      className="col-sm-3 col-form-label"
                    >
                      หัวข้อ
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        name="title"
                        // value={title}
                        // onChange={(e) => {
                        //   settitle(e.target.value);
                        // }}
                        className={`form-control ${errors.title ? "error-input" : ""
                          }`}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="uplaod-cover"
                      className="col-sm-3 col-form-label"
                    >
                      อัพโหลดรูปภาพ
                    </label>
                    <div className="col-sm-5">
                      <div className="row">
                        <div className="col-10">
                          <img
                            alt=""
                            id="img-upload"
                            src={cover.preview}
                            style={{ maxWidth: "100%", maxHeight: 500 }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/images/no-img.jpg";
                            }}
                          />
                          <div className="input-group">
                            <input
                              type="file"
                              className={`form-control ${errors.cover ? "error-img-upload" : ""
                                }`}
                              accept=".png, .jpg, .jpeg"
                              name="cover"
                              onChange={async (e) => {
                                if (e.target.files.length) {
                                  await setcover({
                                    preview: URL.createObjectURL(
                                      e.target.files[0]
                                    ),
                                    base64: await toBase64(e.target.files[0]),
                                  });

                                  // trigger("cover");
                                }
                              }}
                            // ref={(e) => {
                            //   register(
                            //     { name: "cover" },
                            //     {
                            //       validate: () => {
                            //         return cover.preview || cover.base64
                            //           ? true
                            //           : false;
                            //       },
                            //     }
                            //   );
                            // }}
                            />
                          </div>
                        </div>

                        <div className="col">
                          <button
                            className="btn btn-light btn-sm mt-1"
                            onClick={async () => {
                              await setcover({
                                preview: null,
                                base64: null,
                              });
                              // trigger("cover");
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="detail-html"
                      className="col-sm-3 col-form-label"
                    >
                      รายละเอียด
                    </label>
                    <div className="col-sm-8">
                      <Editor
                        editorStyle={{
                          border: "1px solid #F1F1F1",
                          borderRadius: 3,
                          height: 400,
                          lineHeight: 1,
                        }}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: [
                            "inline",
                            //   "blockType",
                            "fontSize",
                            //   "fontFamily",
                            "image",
                            "colorPicker",
                            "textAlign",
                            "history",
                          ],
                          inline: {
                            options: ["bold", "italic", "underline"],
                          },
                          // blockType: { visible: false },
                          // fontSize: { visible: false },
                          // fontFamily: { visible: false },
                          // list: {
                          //   visible: false,
                          //   inDropdown: true,
                          //   unordered: { visible: false },
                          //   ordered: { visible: false },
                          //   indent: { visible: false },
                          //   outdent: { visible: false },
                          // },
                          // textAlign: {
                          //   visible: false,
                          //   inDropdown: true,
                          //   left: { visible: false },
                          //   center: { visible: false },
                          //   right: { visible: false },
                          //   justify: { visible: false },
                          // },
                          // colorPicker: { visible: false },
                          // link: {
                          //   visible: false,
                          //   inDropdown: true,
                          //   addLink: { visible: false },
                          //   removeLink: { visible: false },
                          // },
                          // history: {
                          //   visible: false,
                          //   inDropdown: true,
                          //   undo: { visible: false },
                          //   redo: { visible: false },
                          // },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            previewImage: true,
                            alt: { present: true, mandatory: false },
                            inputAccept: "image/jpeg,image/jpg",
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      ส่งข่าวถึง
                    </label>
                    <div className="col-sm-5">
                      <Select
                        value={send_to}
                        onChange={async (e) => {
                          await setsend_to(e);
                          trigger("send_to");
                        }}
                        options={[
                          { value: "user", label: "สมาชิก" },
                          { value: "rider", label: "พนักงานขับรถ" },
                        ]}
                        placeholder={"เลือก.."}
                        closeMenuOnSelect={false}
                        isMulti
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.send_to ? "red" : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.send_to ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        ref={(e) => {
                          register(
                            { name: "send_to" },
                            {
                              validate: (value) => {
                                return send_to.length === 0 ? false : true;
                              },
                            }
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">วันที่ส่ง</label>
                    <div className="col-sm-5">
                      <div className="row">
                        <div className="col-7">
                          <DayPickerInput
                            value={effective_day}
                            onDayChange={async (e) => {
                              await seteffective_day(e);
                            }}
                            placeholder={"วัน/เดือน/ปี"}
                            style={{ width: "100%", height: "100%" }}
                            inputProps={{
                              style: {
                                width: "100%",
                                padding: 5,
                                border: "1px solid #cdd4e0",
                                borderRadius: 5,
                                height: "100%",
                              },
                            }}
                            overlayComponent={CustomOverlay}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                              disabledDays: { before: new Date() },
                            }}
                          />
                        </div>
                        <div className="col-5">
                          <input
                            className="form-control"
                            type="time"
                            min="00:00"
                            max="60:00"
                            value={effective_time}
                            onChange={(e) => {
                              //   let ef = moment(
                              //     effective_day,
                              //     "DD/MM/YYYY"
                              //   ).format("YYYY-MM-DD");

                              //   let is_af = moment(
                              //     `${ef} ${e.target.value}`,
                              //     "YYYY-MM-DD h:mm"
                              //   );

                              //   if (is_af.isAfter()) {
                              //     seteffective_time(e.target.value);
                              //   }
                              seteffective_time(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="note-html"
                      className="col-sm-3 col-form-label"
                    >
                      หมายเหตุ
                    </label>
                    <div className="col-sm-5">
                      <input
                        name="note"
                        type="text"
                        className="form-control"
                        value={note}
                        onChange={(e) => setnote(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2 ml-auto "
                    >
                      บันทึก
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
