import "react-loadingmask/dist/react-loadingmask.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { api, header_token, img_url, wallet_action } from "../../config";

import Axios from "axios";
import { CreditHead } from "./CreditHead";
import { Link } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import { NotiContext } from "../../store/keep";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { checkImageFlowOld } from '../../utils/Function'
import moment from "moment";
import swal from "sweetalert";

const deposit_type = [
  { value: "", label: "เลือก.." },
  { value: "rider", label: "พนักงานส่ง" },
];

export const CreditIndex = () => {
  const checkAllRef = useRef(null);
  const { noti } = useContext(NotiContext);

  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_name, setfilter_name] = useState("");

  const [filter_type, setfilter_type] = useState({
    value: "",
    label: "เลือก..",
  });
  const [action, setaction] = useState({ value: "", label: "เลือก.." });

  const getData = async () => {
    setloading(true);
    try {
      let send_data = {
        name: filter_name,
        status: 2,
        type: 1,
        is_user: 1,
      };

      let { data } = await Axios.post(
        `${api}/admin/wallet/history?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);
      settotal_count(data.meta.total_count);

      setdata(data.wallets);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const approveDeposit = async (id) => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/wallet/deposit/approve/${id}`,
        header_token
      );

      // console.log(data);
      if (data.code === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  const cancelDeposit = async (id) => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/wallet/deposit/cancel/${id}`,
        header_token
      );
      // console.log("data", data);

      if (data.code === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "credit") {
      getData();
    }
  }, [noti]);

  return (
    <div>
      <CreditHead />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <span className="h5 ml-3 mt-3">
                  ทั้งหมด {total_count} รายการ
                </span>

                <div className="form-row align-items-center mb-2 ml-auto">
                  <div className="col mr-2">
                    <span className="text-muted tx-13">ค้นหา</span>
                    <div style={{ width: 200 }}>
                      <input
                        type="text"
                        className="form-control- global_filter"
                        id="global_filter"
                        placeholder="ชื่อ"
                        value={filter_name}
                        onChange={(e) => {
                          setfilter_name(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="col mr-3">
                    <div className="row">
                      <div style={{ width: 150 }}>
                        <span className="text-muted tx-13">ประเภท</span>
                        <Select
                          value={filter_type}
                          onChange={(e) => {
                            setfilter_type(e);
                          }}
                          options={deposit_type}
                          placeholder={"ประเภท.."}
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="col mr-3">
                    <div className="row">
                      <div style={{ width: 150 }} className="">
                        <span className="tx-13 text-muted">ดำเนินการ</span>
                        <Select
                          value={action}
                          onChange={(e) => {
                            setaction(e);
                          }}
                          options={wallet_action}
                          placeholder={"ดำเนินการ.."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col ">
                    <div className="row">
                      <div style={{ width: 120 }} className="">
                        <a
                          tabIndex="0"
                          id="search"
                          className="btn btn-primary "
                          style={{ marginTop: 23 }}
                          onClick={async () => {
                            let count = data.filter((e) => e.checked === true);
                            if (count.length > 0) {
                              if (action.value === "approve") {
                                swal({
                                  title: `ต้องการอนุมัติเมนูที่เลือกหรือไม่ ?`,
                                  confirmButtonText: "ใช่",
                                  cancelButtonText: "ไม่",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willApprove) => {
                                  if (willApprove) {
                                    let sending = [];
                                    data.forEach((value) => {
                                      if (value.checked === true) {
                                        sending.push(approveDeposit(value.id));
                                      }
                                    });

                                    let is_success = true;
                                    await Promise.all(sending).then((res) => {
                                      console.log(res);
                                      if (
                                        res.filter((e) => e === true).length ===
                                        0 ||
                                        res.length === 0
                                      )
                                        is_success = false;
                                    });

                                    if (is_success) {
                                      getData();

                                      swal("อนุมัติเสร็จสิ้น !!", {
                                        icon: "success",
                                      });
                                    } else {
                                      swal("เกิดข้อผิดพลาด !!", {
                                        icon: "error",
                                      });
                                    }
                                  }
                                });
                              } else if (action.value === "cancel") {
                                swal({
                                  title: `ต้องการยกเลิกเมนูที่เลือกหรือไม่ ?`,
                                  confirmButtonText: "ใช่",
                                  cancelButtonText: "ไม่",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willCancel) => {
                                  if (willCancel) {
                                    let sending = [];
                                    data.forEach((value) => {
                                      if (value.checked === true) {
                                        sending.push(cancelDeposit(value.id));
                                      }
                                    });

                                    let is_success = true;
                                    await Promise.all(sending).then((res) => {
                                      console.log(res);
                                      if (
                                        res.filter((e) => e === true).length ===
                                        0 ||
                                        res.length === 0
                                      )
                                        is_success = false;
                                    });

                                    if (is_success) {
                                      getData();
                                      swal("ยกเลิกเสร็จสิ้น !!", {
                                        icon: "success",
                                      });
                                    } else {
                                      swal("เกิดข้อผิดพลาด !!", {
                                        icon: "error",
                                      });
                                    }
                                  }
                                });
                              }
                            } else {
                              alert("เลือกตัวดำเนินการ");
                            }
                          }}
                        >
                          ดำเนินการ
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table
                      id="dataTable"
                      className="table "
                      style={{ width: "100%" }}
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th style={{ minWidth: 30 }}>
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                // console.log(checkAllRef.current.checked);
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, idx) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });
                                setdata(_tmp);
                                // console.log(data);
                              }}
                            />
                          </th>
                          <th style={{ minWidth: 50 }}>รหัส</th>
                          <th style={{ minWidth: 250 }}>ชื่อ</th>
                          <th style={{ minWidth: 250 }}>พื้นที่</th>
                          <th style={{ minWidth: 120 }}>จำนวนเงิน</th>
                          <th style={{ minWidth: 120 }}>ไฟล์แนบ</th>
                          <th style={{ minWidth: 150 }}>เครดิตคงเหลือ</th>
                          <th style={{ minWidth: 120 }}>ประเภท</th>
                          <th style={{ minWidth: 200 }}>วันที่</th>
                          <th style={{ minWidth: 200 }}>สถานะ</th>

                          <th style={{ minWidth: 180 }}>ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked ? "#e8eaed" : "",
                              }}
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td>{value.user.user_id}</td>

                              <td>
                                <Link to={`/rider/credit/${value.user.id}`}>
                                  {value.user.full_name}
                                </Link>
                              </td>
                              <td>
                                {value.user.area ? value.user.area.name_th : ""}
                              </td>
                              <td className="text-success">
                                ฿{value.amount.toLocaleString()}
                              </td>
                              <td className="dt-edit">
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : checkImageFlowOld(value.picture) ? `${img_url}${value.picture}` : value.picture
                                    );
                                  }}
                                >
                                  <img
                                    className="lazy img-responsive"
                                    src={
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : checkImageFlowOld(value.picture) ? `${img_url}${value.picture}` : value.picture
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = "/images/no-img.jpg";
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                {`฿${value.user.credit.toLocaleString()}`}
                              </td>
                              <td>{value.user_id ? "พนักงาน" : ""}</td>
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "อนุมัติ";
                                    case 2:
                                      return "รอตรวจสอบ";
                                    case 3:
                                      return "รอดำเนินการ";
                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>
                                <button
                                  className="btn btn-outline-primary mb-1 mr-1"
                                  onClick={() => {
                                    swal({
                                      title: `ต้องการอนุมัติใช่หรือไม่ ?`,
                                      confirmButtonText: "ใช่",
                                      cancelButtonText: "ย้อนกลับ",
                                      icon: "warning",
                                      buttons: true,
                                      // dangerMode: true,
                                    }).then(async (willDelete) => {
                                      if (willDelete) {
                                        let is_success = await approveDeposit(
                                          value.id
                                        );
                                        getData();

                                        if (is_success) {
                                          getData();
                                          swal("อนุมัติเสร็จสิ้น !!", {
                                            icon: "success",
                                          });
                                        } else {
                                          swal("เกิดข้อผิดพลาด !!", {
                                            icon: "error",
                                          });
                                        }
                                      }
                                    });
                                  }}
                                >
                                  อนุมัติ
                                </button>
                                <button
                                  className="btn btn-outline-primary mb-1"
                                  onClick={() => {
                                    swal({
                                      title: `ต้องการยกเลิกใช่หรือไม่ ?`,
                                      confirmButtonText: "ใช่",
                                      cancelButtonText: "ย้อนกลับ",
                                      icon: "warning",
                                      buttons: true,
                                      // dangerMode: true,
                                    }).then(async (willDelete) => {
                                      if (willDelete) {
                                        let is_success = await cancelDeposit(
                                          value.id
                                        );
                                        getData();
                                        if (is_success) {
                                          swal("ยกเลิกเสร็จสิ้น !!", {
                                            icon: "success",
                                          });
                                        } else {
                                          swal("เกิดข้อผิดพลาด !!", {
                                            icon: "error",
                                          });
                                        }
                                      }
                                    });
                                  }}
                                >
                                  ยกเลิก
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-2" style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={page_count}
                    onPageChange={(e) => setcurrent_page(e.selected + 1)}
                    forcePage={current_page - 1}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                  />
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/no-img.jpg";
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
