import React, { useEffect, useRef } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportRiderService = ({ data, trigger, settrigger }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Rider_service_export_${moment(new Date()).format(
        "DD-MM-YYYY"
      )}`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Rider">
        <ExcelColumn label="Order No" value="order_no" />
        <ExcelColumn label="วันที่" value="created_at" />
        <ExcelColumn label="สถานะ" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
};
