import React, { useEffect, useState } from "react";
import { api, header_token, image_size } from "../../config";

import $ from "jquery";
import Axios from "axios";
import { CarServiceHead } from "./CarServiceHead";
import Select from "react-select";
import _ from "lodash";
import dropify from "dropify";
import shortid from "shortid";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const activeOptions = [
  { label: "ใช้งาน", value: 1 },
  { label: "ระงับ", value: 0 },
];

const option_types = [
  {
    label: "จำนวณเต็ม",
    value: 0,
  },
  {
    label: "เปอร์เซ็นต์ (%)",
    value: 1,
  },
];

export const CarServiceAdd = () => {
  const { register, handleSubmit, errors, control, trigger, watch, setValue } =
    useForm({
      defaultValues: {
        commission_per_distance_type: 1,
        commission_charge_per_point_type: 1,
      },
    });
  const { push } = useHistory();
  const [serviceOptions, setServiceOptions] = useState([])
  const [isOptionSelected, setIsOptionSelected] = useState(0)
  const [service_img, setservice_img] = useState({});
  const [status, setstatus] = useState(activeOptions[0]);
  const [rings, setring] = useState([
    {
      at: 1,
      distance: -1,
      dispatch_delay_sec: 90
    },
  ]);
  const [service_type, setservice_type] = useState({
    label: "คิดค่าบริการตามระบบ",
    value: 0,
  });
  const [distanceRanges, setDistanceRanges] = useState([
    {
      at: 1,
      charge: 0,
    },
    {
      at: 0,
      charge: 0,
    },
  ]);

  var serviceOptionDefault = {
    "start_at_distance": 0,
    "start_fee": 0,
    "start_price": 0,
    "percentage_commission_distance": 0,
    "charge_per_kilometer": 0,
    "charge_per_point": 0,
    "commission_charge_per_point": 0,
    "commission_charge_per_point_type": 1,
    "distance_ranges": [
      {
        at: 1,
        charge: 0,
      },
      {
        at: 0,
        charge: 0,
      },
    ],
    "commission_per_distance": 0,
    "commission_per_distance_type": 1,
  }

  const onAddServiceOption = () => {
    serviceOptionDefault.start_at_distance = serviceOptions[isOptionSelected].start_at_distance + 20
    setServiceOptions((prev) => {
      return [
        ...prev,
        serviceOptionDefault,
      ];
    });
  };

  const onRemoveServiceOption = () => {
    setServiceOptions((prev) => {
      prev.splice(isOptionSelected, 1)
      return [...prev];
    });
    setIsOptionSelected(0)
  };

  const onAddDistanceRing = () => {
    setring((prev) => {
      return [
        { at: prev.length + 1, distance: 1, dispatch_delay_sec: 30 },
        ...prev,
      ];
    });
  };

  const onRemoveDistanceRing = (index) => {
    setring(prev => prev.filter((s, i) => (i !== index)))
  };

  const onAddDistanceRange = () => {
    setDistanceRanges((prev) => {
      const last = prev.pop();
      return [
        ...prev,
        { at: prev[prev.length - 1].at + 1, charge: 0 },
        { ...last },
      ];
    });
  };

  const onRemoveDistanceRange = () => {
    setDistanceRanges((prev) => {
      const last = prev.pop();
      prev.pop();
      return [...prev, { ...last }];
    });
  };

  const onDistanceRangeChange = (name, index, value) => {
    // setDistanceRanges((prev) => {
    //   prev[index][name] = name === "at" ? parseInt(value) : parseFloat(value);
    //   return [...prev];
    // });
    setServiceOptions((prev) => {
      prev[isOptionSelected].distance_ranges[index][name] = name === "at" ? parseInt(value) : parseFloat(value);
      return [...prev];
    })
  };

  const onDistanceRingChange = (name, index, value) => {
    setring((prev) => {
      prev[index][name] = parseInt(value);
      return [...prev];
    });
  };

  // const [addon, setaddon] = useState([
  //   {
  //     id: "",
  //     name: "",
  //     price: 0,
  //     percentage_commission: 0,
  //     edit: true,
  //     new: true,
  //   },
  // ]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = async (value) => {
    try {
      const options = []
      for (let index = 0; index < serviceOptions.length; index++) {
        const opt = serviceOptions[index];
        options.push({
          charge_per_kilometer: service_type.value === 0 ? opt.charge_per_kilometer : 0,
          charge_per_point: service_type.value === 0 ? opt.charge_per_point : 0,
          percentage_commission_distance: service_type.value === 0 ? opt.percentage_commission_distance : 0,
          start_fee: service_type.value === 1 ? opt.start_fee : 0,
          start_price: service_type.value === 1 ? opt.start_price : 0,
          commission_per_distance: opt.commission_per_distance,
          commission_per_distance_type: opt.commission_per_distance_type ?? 0,
          commission_charge_per_point: opt.commission_charge_per_point ?? 0,
          commission_charge_per_point_type: opt.commission_charge_per_point_type ?? 0,
          distance_ranges: serviceOptions[index].distance_ranges,
          start_at_distance: serviceOptions[index].start_at_distance,
        })
      }

      let send_data = {
        area_distance: parseInt(value.area_distance),
        detail: value.detail.trim(),
        short_description: value.short_description.trim(),
        name: value.name.trim(),
        short_description_en: value.short_description_en.trim(),
        name_en: value.name_en.trim(),
        picture: service_img.base64,
        service_type: parseInt(service_type.value),
        status: parseInt(status.value),
        area_distance_extent: rings,
        service_type_option: options,
      };

      let response = await Axios.post(
        `${api}/admin/car-service`,
        send_data,
        header_token
      );

      if (response.status === 200 || response.status === 201) {
        swal("เพิ่มบริการรถเสร็จสิ้น !!", {
          icon: "success",
          timer: 1500,
        });
        setTimeout(() => {
          push(`/car_service/edit/${response.data.id}`);
        }, 1600);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    $(".dropify").dropify();

    var drEventRider = $("#carservice-img").dropify();

    drEventRider.on("dropify.afterClear", async function (event, element) {
      await setservice_img({
        preview: "",
        base64: "",
      });
      await trigger("service_img");
    });
    setServiceOptions([serviceOptionDefault])
  }, []);

  return (
    <div>
      <CarServiceHead />
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>เพิ่มบริการรถ</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4">
                                <label htmlFor="name">รูป Icon(150*75)</label>
                              </div>
                              <div className="col-sm-7" id="preview-img-div">
                                <div className="mb-2">
                                  {errors.service_img ? (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                      style={{ width: "100%", padding: 5 }}
                                    >
                                      กรุณาอัพโหลดไฟล์
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <input
                                  type="file"
                                  id="carservice-img"
                                  className="dropify"
                                  data-max-file-size="3M"
                                  accept=".png, .jpg, .jpeg"
                                  data-default-file=""
                                  onChange={async (e) => {
                                    if (e.target.files.length) {
                                      let img_file = e.target.files[0];
                                      let base64img = await toBase64(img_file);
                                      if (
                                        await image_size(base64img, 150, 75)
                                      ) {
                                        setservice_img({
                                          preview:
                                            URL.createObjectURL(img_file),
                                          base64: base64img,
                                        });
                                        await trigger("service_img");
                                      } else {
                                        setTimeout(() => {
                                          $(
                                            "#preview-img-div .dropify-wrapper .dropify-clear"
                                          ).trigger("click");
                                          alert("ขนาดภาพเกินกว่าที่กำหนด");
                                        }, 50);
                                      }
                                    }
                                  }}
                                  ref={(e) => {
                                    register(
                                      { name: "service_img" },
                                      {
                                        validate: (value) => {
                                          return service_img.base64 ===
                                            undefined ||
                                            service_img.base64 === ""
                                            ? false
                                            : true;
                                        },
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                ชื่อ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`name`}
                                  name={`name`}
                                  type="text"
                                  className={`form-control ${errors.name ? "error-input" : ""
                                    }`}
                                  placeholder="ชื่อ"
                                  ref={register({
                                    required: true,
                                  })}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                ชื่อภาษาอังกฤษ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`name_en`}
                                  name={`name_en`}
                                  type="text"
                                  className={`form-control ${errors.name_en ? "error-input" : ""
                                    }`}
                                  placeholder="ชื่อภาษาอังกฤษ"
                                  ref={register({
                                    required: true,
                                  })}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                รูปแบบการบริการ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`detail`}
                                  name={`detail`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.detail ? "error-input" : ""
                                    }`}
                                  type="text"
                                  placeholder="รายละเอียด"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                คำอธิบายโดยย่อ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`short_description`}
                                  name={`short_description`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.short_description
                                    ? "error-input"
                                    : ""
                                    }`}
                                  type="text"
                                  placeholder="รายละเอียด"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                คำอธิบายโดยย่อภาษาอังกฤษ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`short_description_en`}
                                  name={`short_description_en`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.short_description_en
                                    ? "error-input"
                                    : ""
                                    }`}
                                  type="text"
                                  placeholder="คำอธิบายโดยย่อภาษาอังกฤษ"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-4">
                                <label htmlFor="name">สถานะ</label>
                              </div>
                              <div className="col-sm-7">
                                <Select
                                  name="status"
                                  options={activeOptions}
                                  defaultValue={status}
                                  onChange={async (e) => {
                                    setstatus(e);
                                    await trigger("status");
                                  }}
                                  placeholder={"สถานะ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.status
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.status
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register({ name: "status" });
                                  }}
                                />
                              </div>
                            </div>

                            {/* <div className=" row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "15%" }}
                              >
                                บันทึก
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <hr style={{ margin: 0 }} />
                      <div style={{ padding: "1.25rem 1.25rem" }}>
                        <h4>ระยะการให้บริการ</h4>
                      </div>
                      {/* <hr style={{ margin: 0 }} /> */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4 col-form-label">
                                ระยะทางรับงานครอบคลุม(km)
                              </div>
                              <div className="col-sm-7">
                                <input
                                  type="number"
                                  // placeholder="10"
                                  style={{ width: 100 }}
                                  id={`area_distance`}
                                  name={`area_distance`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.area_distance ? "error-input" : ""
                                    }`}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-4 col-form-label">
                                การตั้งค่าการกระจายงาน
                              </div>
                              <div className="col-sm-7">
                                {
                                  rings.map((ring, index) => {
                                    return (
                                      <div key={ring.at + '' + index}>
                                        <div className="row col-form-label col-sm-2">
                                          ระยะที่ {index + 1}
                                        </div>
                                        <div className="row">
                                          <div className="col-form-label col-sm-3">
                                            ระยะทาง (km)
                                          </div>
                                          <input
                                            type={ring.at === 1 ? "text" : "number"}
                                            readOnly={ring.at === 1}
                                            min={1}
                                            // placeholder="10"
                                            style={{ width: 100 }}
                                            className={`form-control`}
                                            defaultValue={ring.at === 1 ? 'ทั้งหมด' : ring.distance}
                                            onBlur={(e) => {
                                              onDistanceRingChange(
                                                "distance",
                                                index,
                                                e.currentTarget.value
                                              );
                                            }}
                                          />
                                          <div className="col-form-label col-sm-3">
                                            ระยะเวลา (วินาที)
                                          </div>
                                          <input
                                            type={"number"}
                                            style={{ width: 100 }}
                                            className={`form-control`}
                                            min={1}
                                            defaultValue={ring.dispatch_delay_sec}
                                            onBlur={(e) => {
                                              onDistanceRingChange(
                                                "dispatch_delay_sec",
                                                index,
                                                e.currentTarget.value
                                              );
                                            }}
                                          />
                                          <div className="col-form-label col-sm-1" />
                                          {
                                            ring.distance !== -1 && <button
                                              className="btn btn-danger"
                                              type="button"
                                              onClick={() => {
                                                onRemoveDistanceRing(index)
                                              }}
                                            >
                                              -
                                            </button>
                                          }
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                                <div className="row col-form-label col-sm-2">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      onAddDistanceRing();
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr style={{ margin: 0 }} />
                      <div style={{ padding: "1.25rem 1.25rem" }}>
                        <h4>ค่าบริการ</h4>
                      </div>
                      {/* <hr style={{ margin: 0 }} /> */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4">
                                รูปแบบการคิดค่าบริการ
                              </div>
                              <div className="col-sm-7">
                                <Select
                                  name="service_type"
                                  options={[
                                    {
                                      label: "คิดค่าบริการตามระบบ",
                                      value: 0,
                                    },
                                    {
                                      label:
                                        "คิดค่าบริการตามจริง/มิเตอร์/แล้วแต่ตกลงกับผู้ให้บริการ",
                                      value: 1,
                                    },
                                  ]}
                                  value={service_type}
                                  defaultValue={service_type}
                                  onChange={async (e) => {
                                    setservice_type(e);
                                    await trigger("service_type");
                                  }}
                                  placeholder={"รูปแบบ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.service_type
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.service_type
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register({ name: "service_type" });
                                  }}
                                />
                              </div>
                            </div>

                            {
                              service_type.value !== 1 &&
                              <div className="row form-group" style={{ justifyItems: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3, overflowX: 'scroll', display: 'flex', flexWrap: 'nowrap' }}>
                                {
                                  serviceOptions.length > 0 && serviceOptions.map((opt, index) => {
                                    return <div className="row" key={'option-service-' + index} style={{ marginRight: 10, marginLeft: (index === 0 ? 2 : undefined), }}>
                                      <button
                                        className={"btn " + (isOptionSelected === index ? "btn-primary" : "btn-outline-primary")}
                                        style={{ marginRight: 10 }}
                                        type="button"
                                        onClick={() => {
                                          setIsOptionSelected(index)
                                        }}
                                      >
                                        <span>เงื่อนไขที่ {index + 1}</span>
                                      </button>
                                    </div>
                                  })
                                }
                                {
                                  isOptionSelected !== 0 && <div className="row" style={{ marginRight: 20 }}>
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        onRemoveServiceOption();
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>
                                }
                                {
                                  serviceOptions.length < 10 && <div className="row">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        onAddServiceOption();
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                }
                              </div>
                            }

                            {service_type.value !== 1 && serviceOptions.length > 0 ? (
                              <>
                                {
                                  serviceOptions.length > 0 && <div
                                    className="form-group row"
                                    key={`distance-range-${shortid.generate()}`}
                                  >
                                    <div className="col-sm-4">
                                      <span className="">
                                        เงื่อนไขระยะทาง
                                      </span>
                                    </div>
                                    <div className="row col-sm-8">
                                      <div className="col-sm-2">
                                        <input
                                          type="number"
                                          style={{ width: 100 }}
                                          defaultValue={serviceOptions[isOptionSelected].start_at_distance || 0}
                                          onBlur={(e) => {
                                            const val = parseFloat(e.currentTarget.value) || 0
                                            setServiceOptions((prev) => {
                                              prev[isOptionSelected].start_at_distance = val
                                              return [...prev]
                                            })
                                          }}
                                          min={isOptionSelected === 0 ? 0 : (serviceOptions[isOptionSelected - 1].start_at_distance + 1)}
                                          className={`form-control`}
                                        />
                                      </div>
                                      <div className="col-sm-2 d-flex align-items-center">
                                        กม. ขึ้นไป
                                      </div>
                                    </div>
                                  </div>
                                }
                                {serviceOptions.length > 0 && serviceOptions[isOptionSelected].distance_ranges !== undefined
                                  && serviceOptions[isOptionSelected].distance_ranges.map((_, i) => {
                                    const range = serviceOptions[isOptionSelected].distance_ranges[i];
                                    const disRanges = serviceOptions[isOptionSelected].distance_ranges

                                    if (i === 0) {
                                      return (
                                        <div
                                          className="form-group row"
                                          key={`distance-range-${shortid.generate()}`}
                                        >
                                          <div className="col-sm-4">
                                            <span className="">
                                              ค่าบริการเริ่มต้นในช่วง
                                            </span>
                                          </div>
                                          <div className="col-sm-8 row">
                                            <div className="col-sm-2">
                                              <input
                                                type="number"
                                                style={{ width: 100 }}
                                                defaultValue={range.at}
                                                onBlur={(e) => {
                                                  if (
                                                    e.currentTarget.value
                                                      .length === 0
                                                  ) {
                                                    e.currentTarget.value =
                                                      range.at;
                                                    return;
                                                  }
                                                  onDistanceRangeChange(
                                                    "at",
                                                    i,
                                                    e.currentTarget.value
                                                  );
                                                }}
                                                readOnly={
                                                  disRanges.length > 2
                                                }
                                                min={1}
                                                className={`form-control`}
                                              />
                                            </div>
                                            <div className="col-sm-1 d-flex align-items-center">
                                              กม.แรก
                                            </div>
                                            <div className="col-sm-2">
                                              <input
                                                type="number"
                                                style={{ width: 100 }}
                                                defaultValue={range.charge}
                                                min={1}
                                                onBlur={(e) => {
                                                  if (
                                                    e.currentTarget.value
                                                      .length === 0
                                                  ) {
                                                    e.currentTarget.value =
                                                      range.charge;
                                                    return;
                                                  }
                                                  onDistanceRangeChange(
                                                    "charge",
                                                    i,
                                                    e.currentTarget.value
                                                  );
                                                }}
                                                step={0.01}
                                                className={`form-control`}
                                              />
                                            </div>
                                            <div className="col-sm-1 d-flex align-items-center">
                                              บาท
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }

                                    if (i === disRanges.length - 1) {
                                      return (
                                        <div
                                          key={`distance-range-${shortid.generate()}`}
                                        >
                                          <div className="form-group row">
                                            <div className="col-sm-4">
                                              <span className="">
                                                เพิ่มช่วงระยะทาง
                                              </span>
                                            </div>
                                            <div className="col-sm-8">
                                              <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={onAddDistanceRange}
                                              >
                                                +
                                              </button>
                                            </div>
                                          </div>
                                          <div className="form-group row">
                                            <div className="col-sm-4">
                                              <span className="">
                                                ค่าบริการช่วงระยะทาง
                                              </span>
                                            </div>
                                            <div className="col-sm-8 row">
                                              <div className="col-sm-2">
                                                <input
                                                  type="number"
                                                  style={{ width: 100 }}
                                                  defaultValue={
                                                    disRanges[i - 1].at + 0.1
                                                  }
                                                  readOnly
                                                  min={
                                                    disRanges[i - 1].at + 0.1
                                                  }
                                                  onBlur={(e) => {
                                                    if (
                                                      e.currentTarget.value
                                                        .length === 0
                                                    ) {
                                                      e.currentTarget.value =
                                                        range.at;
                                                      return;
                                                    }
                                                    onDistanceRangeChange(
                                                      "at",
                                                      i,
                                                      e.currentTarget.value
                                                    );
                                                  }}
                                                  className={`form-control`}
                                                />
                                              </div>
                                              <div className="col-sm-1 d-flex align-items-center">
                                                ขึ้นไปกม.ละ
                                              </div>
                                              <div className="col-sm-2">
                                                <input
                                                  type="number"
                                                  style={{ width: 100 }}
                                                  defaultValue={range.charge}
                                                  min={1}
                                                  className={`form-control`}
                                                  onBlur={(e) => {
                                                    if (
                                                      e.currentTarget.value
                                                        .length === 0
                                                    ) {
                                                      e.currentTarget.value =
                                                        range.charge;
                                                      return;
                                                    }
                                                    onDistanceRangeChange(
                                                      "charge",
                                                      i,
                                                      e.currentTarget.value
                                                    );
                                                  }}
                                                  step={0.01}
                                                />
                                              </div>
                                              <div className="col-sm-1 d-flex align-items-center">
                                                บาท
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return (
                                      <div
                                        className="form-group row"
                                        key={`distance-range-${shortid.generate()}`}
                                      >
                                        <div className="col-sm-4">
                                          <span className="">
                                            ค่าบริการช่วงระยะทางที่
                                          </span>
                                        </div>
                                        <div className="col-sm-8 row">
                                          <div className="col-sm-2">
                                            <input
                                              type="number"
                                              style={{ width: 100 }}
                                              defaultValue={
                                                disRanges[i - 1].at + 0.1
                                              }
                                              readOnly
                                              min={disRanges[i - 1].at + 0.1}
                                              className={`form-control`}
                                            />
                                          </div>
                                          <div className="col-sm-1 d-flex align-items-center">
                                            ถึง
                                          </div>
                                          <div className="col-sm-2">
                                            <input
                                              type="number"
                                              style={{ width: 100 }}
                                              defaultValue={range.at}
                                              min={disRanges[i - 1].at + 1}
                                              readOnly={
                                                i !== disRanges.length - 2
                                              }
                                              onBlur={(e) => {
                                                if (
                                                  e.currentTarget.value.length ===
                                                  0
                                                ) {
                                                  e.currentTarget.value =
                                                    range.at;
                                                  return;
                                                }
                                                if (
                                                  parseInt(
                                                    e.currentTarget.value
                                                  ) <=
                                                  disRanges[i - 1].at + 0.1
                                                ) {
                                                  e.currentTarget.value =
                                                    range.at;
                                                  return;
                                                }
                                                onDistanceRangeChange(
                                                  "at",
                                                  i,
                                                  e.currentTarget.value
                                                );
                                              }}
                                              className={`form-control`}
                                            />
                                          </div>
                                          <div className="col-sm-1 d-flex align-items-center">
                                            กม. ละ
                                          </div>
                                          <div className="col-sm-2">
                                            <input
                                              type="number"
                                              style={{ width: 100 }}
                                              defaultValue={range.charge}
                                              min={1}
                                              className={`form-control`}
                                              onBlur={(e) => {
                                                if (
                                                  e.currentTarget.value.length ===
                                                  0
                                                ) {
                                                  e.currentTarget.value =
                                                    range.charge;
                                                  return;
                                                }
                                                onDistanceRangeChange(
                                                  "charge",
                                                  i,
                                                  e.currentTarget.value
                                                );
                                              }}
                                              step={0.01}
                                            />
                                          </div>
                                          <div className="col-sm-1 d-flex align-items-center">
                                            บาท
                                          </div>
                                          {i === disRanges.length - 2 && (
                                            <div className="col-sm-2">
                                              <button
                                                className="btn btn-danger"
                                                type="button"
                                                onClick={onRemoveDistanceRange}
                                              >
                                                -
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })
                                }
                                <div className="form-group row">
                                  <div className="col-sm-4">
                                    <span className="">
                                      ค่าคอมมิชชั่นตามระยะทาง
                                    </span>
                                  </div>
                                  <div className="col-sm-4">
                                    <input
                                      type="number"
                                      // placeholder="10"
                                      style={{ width: 100 }}
                                      id={`commission_per_distance-${isOptionSelected}`}
                                      name={`commission_per_distance-${isOptionSelected}`}
                                      key={`commission_per_distance-${isOptionSelected}`}
                                      // ref={register({
                                      //   required: true,
                                      // })}
                                      className={`form-control ${errors.commission_per_distance
                                        ? "error-input"
                                        : ""
                                        }`}
                                      defaultValue={
                                        // data.commission_per_distance
                                        serviceOptions[isOptionSelected].commission_per_distance
                                      }
                                      step={0.01}
                                      onBlur={(e) => {
                                        // onChangeCommissionPerDistance(e.target.value)
                                        const val = parseFloat(e.target.value) || 0

                                        setServiceOptions((prev) => {
                                          prev[isOptionSelected].commission_per_distance = val
                                          return [...prev]
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className="col-sm-4">
                                    <Select
                                      key={`commission_per_distance_type-${isOptionSelected}`}
                                      name={`commission_per_distance_type-${isOptionSelected}`}
                                      options={[
                                        {
                                          label: "จำนวณเต็ม",
                                          value: 0,
                                        },
                                        {
                                          label: "เปอร์เซ็นต์ (%)",
                                          value: 1,
                                        },
                                      ]}
                                      defaultValue={
                                        option_types[
                                        // data.commission_per_distance_type
                                        serviceOptions[isOptionSelected].commission_per_distance_type
                                        ]
                                      }
                                      onChange={async (e) => {
                                        setServiceOptions((prev) => {
                                          prev[isOptionSelected].commission_per_distance_type = e.value
                                          return [...prev]
                                        })
                                      }}
                                      placeholder={"รูปแบบ..."}
                                      styles={{
                                        control: (base, state) => ({
                                          ...base,
                                          borderColor: errors.service_type
                                            ? "red"
                                            : "#cdd4e0",
                                          "&:hover": {
                                            borderColor: errors.service_type
                                              ? "red"
                                              : "#cdd4e0",
                                          },
                                        }),
                                      }}
                                      ref={(e) => {
                                        register({
                                          name: "commission_per_distance_type",
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-4">
                                    <span className="">
                                      ค่าบริการเพิ่มจุด/จุด(บาท)
                                    </span>
                                  </div>
                                  <div className="col-sm-7">
                                    <input
                                      type="number"
                                      // placeholder="15"
                                      style={{ width: 100 }}
                                      key={`charge_per_point-${isOptionSelected}`}
                                      id={`charge_per_point-${isOptionSelected}`}
                                      name={`charge_per_point-${isOptionSelected}`}
                                      ref={register({
                                        required: true,
                                      })}
                                      className={`form-control ${errors.charge_per_point
                                        ? "error-input"
                                        : ""
                                        }`}
                                      defaultValue={serviceOptions[isOptionSelected].charge_per_point}
                                      step={0.01}
                                      onBlur={(e) => {
                                        const val = parseFloat(e.target.value) || 0

                                        setServiceOptions((prev) => {
                                          prev[isOptionSelected].charge_per_point = val
                                          return [...prev]
                                        })
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-4">
                                    <span className="">
                                      ค่าคอมมิชชั่นบริการเพิ่มจุด
                                    </span>
                                  </div>
                                  <div className="col-sm-4">
                                    <input
                                      type="number"
                                      // placeholder="10"
                                      style={{ width: 100 }}
                                      id={`commission_charge_per_point-${isOptionSelected}`}
                                      name={`commission_charge_per_point-${isOptionSelected}`}
                                      key={`commission_charge_per_point-${isOptionSelected}`}
                                      ref={register({
                                        required: true,
                                      })}
                                      className={`form-control ${errors.commission_charge_per_point
                                        ? "error-input"
                                        : ""
                                        }`}
                                      defaultValue={
                                        serviceOptions[isOptionSelected].commission_charge_per_point
                                      }
                                      step={0.01}
                                      onBlur={(e) => {
                                        const val = parseFloat(e.target.value) || 0

                                        setServiceOptions((prev) => {
                                          prev[isOptionSelected].commission_charge_per_point = val
                                          return [...prev]
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className="col-sm-4">
                                    <Select
                                      name={`commission_charge_per_point_type-${isOptionSelected}`}
                                      key={`commission_charge_per_point_type-${isOptionSelected}`}
                                      options={[
                                        {
                                          label: "จำนวณเต็ม",
                                          value: 0,
                                        },
                                        {
                                          label: "เปอร์เซ็นต์ (%)",
                                          value: 1,
                                        },
                                      ]}
                                      defaultValue={
                                        option_types[
                                        serviceOptions[isOptionSelected].commission_charge_per_point_type
                                        ]
                                      }
                                      onChange={async (e) => {
                                        setServiceOptions((prev) => {
                                          prev[isOptionSelected].commission_charge_per_point_type = e.value
                                          return [...prev]
                                        })
                                      }}
                                      placeholder={"รูปแบบ..."}
                                      styles={{
                                        control: (base, state) => ({
                                          ...base,
                                          borderColor: errors.service_type
                                            ? "red"
                                            : "#cdd4e0",
                                          "&:hover": {
                                            borderColor: errors.service_type
                                              ? "red"
                                              : "#cdd4e0",
                                          },
                                        }),
                                      }}
                                      ref={(e) => {
                                        register({
                                          name: "commission_charge_per_point_type",
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {
                                  serviceOptions.length > 0 && <div className="form-group row">
                                    <div className="col-sm-4">
                                      <span className="">ค่าเรียก(บาท)</span>
                                    </div>
                                    <div className="col-sm-7">
                                      <input
                                        type="number"
                                        // placeholder="25"
                                        style={{ width: 100 }}
                                        id={`start_fee`}
                                        name={`start_fee`}
                                        ref={register({
                                          required: true,
                                        })}
                                        className={`form-control ${errors.start_fee ? "error-input" : ""
                                          }`}
                                        defaultValue={
                                          serviceOptions.length > 0
                                            ? serviceOptions[0].start_fee
                                            : 0
                                        }
                                        onBlur={(e) => {
                                          const val = parseFloat(e.target.value) || 0

                                          if (serviceOptions.length > 0) {
                                            setServiceOptions((prev) => {
                                              prev[0].start_fee = val
                                              return [...prev]
                                            })
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                }
                              </>
                            )}

                            <div className=" row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "15%" }}
                              >
                                บันทึก
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              {/* <div className="row mb-4">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>บริการเสริม</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="content">
                          <table id="example" className="table">
                            <thead>
                              <tr>
                                <th>บริการเสริม</th>
                                <th>ราคา(บาท)</th>
                                <th>หักค่าบริการ(%)</th>
                                <th width="80">Edit</th>
                                <th width="80">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addon.map((value, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="end">
                                      {value.edit === true ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={value.name}
                                          onChange={(e) => {
                                            let tmp = [...addon];
                                            tmp[index].name = e.target.value;
                                            setaddon(tmp);
                                          }}
                                        />
                                      ) : (
                                        value.name
                                      )}
                                    </td>
                                    <td className="cost">
                                      {value.edit === true ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={value.price}
                                          onChange={(e) => {
                                            let tmp = [...addon];
                                            tmp[index].price = parseFloat(
                                              e.target.value
                                            );
                                            setaddon(tmp);
                                          }}
                                        />
                                      ) : (
                                        value.price
                                      )}
                                    </td>
                                    <td className="service">
                                      {value.edit === true ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={value.percentage_commission}
                                          onChange={(e) => {
                                            let tmp = [...addon];
                                            tmp[
                                              index
                                            ].percentage_commission = parseFloat(
                                              e.target.value
                                            );
                                            setaddon(tmp);
                                          }}
                                        />
                                      ) : (
                                        value.percentage_commission
                                      )}
                                    </td>
                                    <td className="edit">
                                      {value.edit === false ? (
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                          onClick={() => {
                                            let tmp = [...addon];
                                            tmp[index].edit = !tmp[index].edit;
                                            setaddon(tmp);
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                          onClick={async () => {
                                            let tmp = [...addon];

                                            if (tmp[index].new === true) {
                                              // console.log("add");
                                              let send_data = {
                                                lte_distance:
                                                  value.lte_distance,
                                                percentage_price_rider_charge:
                                                  value.percentage_price_rider_charge,
                                                price: value.price,
                                              };
                                              // // console.log(tmp[index]);
                                              //   await addCostDistance(send_data);
                                            } else {
                                              let send_data = {
                                                lte_distance:
                                                  tmp[index].lte_distance,
                                                percentage_price_rider_charge:
                                                  tmp[index]
                                                    .percentage_price_rider_charge,
                                                price: tmp[index].price,
                                              };
                                              // console.log("edit", send_data);

                                              //   await editCostDistance(
                                              //     value.id,
                                              //     send_data
                                              //   );
                                            }

                                            // getDataCostDistance();
                                            tmp[index].edit = !tmp[index].edit;
                                            tmp[index].new = false;

                                            setaddon(tmp);
                                          }}
                                        ></i>
                                      )}
                                    </td>

                                    <td className="trash">
                                      <i
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                                        onClick={() => {
                                          if (value.edit === false) {
                                            swal({
                                              title: `ต้องการลบหรือไม่ ?`,
                                              // text:
                                              //   "Once deleted, you will not be able to recover this imaginary file!",
                                              confirmButtonText: "ลบ",
                                              cancelButtonText: "ยกเลิก",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then(async (willDelete) => {
                                              if (willDelete) {
                                                // let is_delete = deleteCostDistance(
                                                //   value.id
                                                // );
                                                // if (is_delete) {
                                                //   swal("ลบเสร็จสิ้น !!", {
                                                //     icon: "success",
                                                //   });
                                                // } else {
                                                //   swal("เกิดข้อผิดพลาด !!", {
                                                //     icon: "error",
                                                //   });
                                                // }
                                              }
                                            });
                                          }
                                        }}
                                      ></i>{" "}
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td
                                  className="new-row"
                                  colspan="7"
                                  onClick={() => {
                                    let tmp = [...addon];
                                    tmp.push({
                                      id: "",
                                      name: "",
                                      price: 0,
                                      percentage_commission: 0,
                                      edit: true,
                                      new: true,
                                    });
                                    setaddon(tmp);
                                  }}
                                >
                                  + Add
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
