import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import { api, header_token } from "../../config";
import { useForm } from "react-hook-form";
import shortid from "shortid";

export const SettingTopup = () => {
  const [loading, setloading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [topupList, setTopupList] = useState([]);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(`${api}/topup`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          id: value.id,
          name_th: value.name_th,
          name_en: value.name_en,
          short_description_th: value.short_description_th,
          short_description_en: value.short_description_en,
          description_th: value.description_th,
          description_en: value.description_en,
          amount: value.amount,
          edit: false,
          new: false,
        });
      });
      setTopupList(tmp);
    } catch (error) {
      // console.log(error);
    }
    setloading(false);
  };

  const addTopup = async (payload) => {
    try {
      let res = await Axios.post(`${api}/admin/topup`, payload, header_token);
      // console.log(res);
      if (res.status === 201) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");

      // console.log(error);
      return false;
    }
  };

  const editTopup = async (topupId, payload) => {
    try {
      let res = await Axios.patch(
        `${api}/admin/topup/${topupId}`,
        payload,
        header_token
      );
      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");
      // console.log(error);
      return false;
    }
  };

  const deleteTopup = async (topupId) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/topup/${topupId}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");

      // console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-12">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {topupList.length} รายการ
                  </h4>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="content">
                        <LoadingMask loading={loading} text={"loading..."}>
                          <div style={{ minHeight: 500 }}>
                            <table id="example" className="table ">
                              <thead>
                                <tr>
                                  <th width="150">จำนวนเงิน</th>
                                  <th>ชื่อ</th>
                                  <th>รายละเอียดแบบย่อ</th>
                                  <th>รายละเอียดแบบเต็ม</th>
                                  <th width="80">Edit</th>
                                  <th width="80">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                {topupList.map((value, index) => {
                                  // console.log("in loop", value);
                                  return (
                                    <tr key={value.id}>
                                      <td className="name">
                                        {value.edit === true ? (
                                          <>
                                            <input
                                              type="number"
                                              className="form-control"
                                              defaultValue={value.amount}
                                              onChange={(e) => {
                                                e.persist();
                                                setTopupList((prev) => {
                                                  let tmp = [...prev];
                                                  tmp[index].amount =
                                                    e.target.value;
                                                  return tmp;
                                                });
                                              }}
                                              placeholder="จำนวนเงิน"
                                              step={1}
                                              min={1}
                                            />
                                          </>
                                        ) : (
                                          <>{value.amount}</>
                                        )}
                                      </td>
                                      <td className="name">
                                        {value.edit === true ? (
                                          <>
                                            <input
                                              type="text"
                                              className="form-control"
                                              defaultValue={value.name_th}
                                              onChange={(e) => {
                                                e.persist();
                                                setTopupList((prev) => {
                                                  let tmp = [...prev];
                                                  tmp[index].name_th =
                                                    e.target.value;
                                                  return tmp;
                                                });
                                              }}
                                              placeholder="ชื่อภาษาไทย"
                                            />
                                            <br />
                                            <input
                                              type="text"
                                              className="form-control"
                                              defaultValue={value.name_en}
                                              onChange={(e) => {
                                                e.persist();
                                                setTopupList((prev) => {
                                                  let tmp = [...prev];
                                                  tmp[index].name_en =
                                                    e.target.value;
                                                  return tmp;
                                                });
                                              }}
                                              placeholder="ชื่อภาษาอังกฤษ"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <p>{value.name_th}</p>
                                            <p>{value.name_en}</p>
                                          </>
                                        )}
                                      </td>
                                      <td className="name">
                                        {value.edit === true ? (
                                          <>
                                            <textarea
                                              className="form-control"
                                              defaultValue={
                                                value.short_description_th
                                              }
                                              onChange={(e) => {
                                                e.persist();
                                                setTopupList((prev) => {
                                                  let tmp = [...prev];
                                                  tmp[
                                                    index
                                                  ].short_description_th =
                                                    e.target.value;
                                                  return tmp;
                                                });
                                              }}
                                              placeholder="คำอธิบายแบบย่อภาษาไทย"
                                              rows={3}
                                            />
                                            <br />
                                            <textarea
                                              className="form-control"
                                              value={value.short_description_en}
                                              onChange={(e) => {
                                                e.persist();
                                                setTopupList((prev) => {
                                                  let tmp = [...prev];
                                                  tmp[
                                                    index
                                                  ].short_description_en =
                                                    e.target.value;
                                                  return tmp;
                                                });
                                              }}
                                              placeholder="คำอธิบายแบบย่อภาษาอังกฤษ"
                                              rows={3}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <p
                                              style={{
                                                whiteSpace: "pre",
                                                overflow: "hidden",
                                                height: 64,
                                              }}
                                            >
                                              {value.short_description_th}
                                            </p>
                                            <p
                                              style={{
                                                whiteSpace: "pre",
                                                overflow: "hidden",
                                                height: 64,
                                              }}
                                            >
                                              {value.short_description_en}
                                            </p>
                                          </>
                                        )}
                                      </td>
                                      <td
                                        className="name"
                                        style={{ whiteSpace: "pre" }}
                                      >
                                        {value.edit === true ? (
                                          <>
                                            <textarea
                                              className="form-control"
                                              defaultValue={
                                                value.description_th
                                              }
                                              onChange={(e) => {
                                                e.persist();
                                                setTopupList((prev) => {
                                                  let tmp = [...prev];
                                                  tmp[index].description_th =
                                                    e.target.value;
                                                  return tmp;
                                                });
                                              }}
                                              placeholder="คำอธิบายแบบเต็มภาษาไทย"
                                              rows={10}
                                            />
                                            <br />
                                            <textarea
                                              type="text"
                                              className="form-control"
                                              value={value.description_en}
                                              onChange={(e) => {
                                                e.persist();
                                                setTopupList((prev) => {
                                                  let tmp = [...prev];
                                                  tmp[index].description_en =
                                                    e.target.value;
                                                  return tmp;
                                                });
                                              }}
                                              placeholder="คำอธิบายแบบเต็มภาษาอังกฤษ"
                                              rows={10}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <p
                                              style={{
                                                whiteSpace: "pre",
                                                overflow: "hidden",
                                                height: 108,
                                              }}
                                            >
                                              {value.description_th}
                                            </p>
                                            <p
                                              style={{
                                                whiteSpace: "pre",
                                                overflow: "hidden",
                                                height: 108,
                                              }}
                                            >
                                              {value.description_en}
                                            </p>
                                          </>
                                        )}
                                      </td>
                                      <td className="edit">
                                        {value.edit === false ? (
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                            onClick={() => {
                                              setTopupList((prev) => {
                                                let tmp = [...prev];
                                                tmp[index].edit =
                                                  !tmp[index].edit;
                                                return tmp;
                                              });
                                            }}
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                            onClick={() => {
                                              let tmp = [...topupList];

                                              if (tmp[index].amount) {
                                                if (tmp[index].new === true) {
                                                  // // console.log(tmp[index]);
                                                  addTopup({
                                                    ...tmp[index],
                                                    amount: parseInt(
                                                      tmp[index].amount
                                                    ),
                                                  });
                                                } else {
                                                  // // console.log("edit");
                                                  let send_data = {
                                                    ...tmp[index],
                                                    amount: parseInt(
                                                      tmp[index].amount
                                                    ),
                                                  };
                                                  editTopup(
                                                    value.id,
                                                    send_data
                                                  );
                                                }

                                                tmp[index].edit =
                                                  !tmp[index].edit;
                                                tmp[index].new = false;

                                                setTopupList(tmp);
                                              }
                                            }}
                                          ></i>
                                        )}
                                      </td>
                                      <td className="trash">
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                                          onClick={() => {
                                            swal({
                                              title: `ต้องการลบ  ${value.name_th} ?`,
                                              // text:
                                              //   "Once deleted, you will not be able to recover this imaginary file!",
                                              confirmButtonText: "ลบ",
                                              cancelButtonText: "ยกเลิก",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then(async (willDelete) => {
                                              if (willDelete) {
                                                let is_delete = deleteTopup(
                                                  value.id
                                                );

                                                if (is_delete) {
                                                  swal("ลบเสร็จสิ้น !!", {
                                                    icon: "success",
                                                  });
                                                } else {
                                                  swal("เกิดข้อผิดพลาด !!", {
                                                    icon: "error",
                                                  });
                                                }
                                              }
                                            });
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                  );
                                })}

                                <tr>
                                  <td
                                    className="new-row"
                                    colSpan="5"
                                    onClick={() => {
                                      // if (
                                      //   area_data[area_data.length - 1].new !==
                                      //   true
                                      // ) {
                                      setTopupList((prev) => {
                                        let tmp = [...prev];
                                        tmp.push({
                                          id: shortid.generate(),
                                          name_th: "",
                                          name_en: "",
                                          short_description_en: "",
                                          short_description_th: "",
                                          description_en: "",
                                          description_th: "",
                                          amount: "",
                                          edit: true,
                                          new: true,
                                        });
                                        return tmp;
                                      });

                                      // }
                                    }}
                                  >
                                    <span className="h3">+</span>{" "}
                                    <span className="h6">Add</span>{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </LoadingMask>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
