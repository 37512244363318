import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { api, header_token } from "../../config";
import moment from "moment";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

export const UserWalletHistory = () => {
  const { id } = useParams();
  const checkAllRef = useRef(null);

  const [loading, setloading] = useState(false);

  const [user, setuser] = useState({});
  const [data, setdata] = useState([]);

  const getProfile = async () => {
    try {
      let { data } = await Axios.get(`${api}/user/profile/${id}`, header_token);

      // console.log("getProfile", data);
      setuser(data);
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/admin/wallet/history/${id}?type=-1&status=1`,
        header_token
      );

      setdata(data);
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getData();
    getProfile();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">Wallet History</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                Wallet History
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col">
                  <h4 className="mt-2">{user.full_name}</h4>
                  <h6 className="text-muted">ID {user.user_id}</h6>
                </div>

                <div className="ml-auto">
                  <div style={{ width: 120 }} className="">
                    {/* <span className="tx-13 text-muted">ดำเนินการ</span>
                    <select className="form-control- ">
                      <option value="">เลือก</option>
                      <option value="">ลบ</option>
                      <option value="">Export</option>
                    </select> */}
                  </div>
                </div>
                <div style={{ width: 130 }}>
                  {/* <a href="" id="search" className="btn btn-primary ml-2"
                                                style="margin-top: 22px;">Export All</a> */}
                </div>
              </div>
              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th>ประเภท</th>
                        <th>จำนวนเงิน</th>
                        <th>วันที่</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {value.type === 0 ? "คืนเงิน" : ""}
                              {value.type === 1
                                ? `เติมเงิน (${value.method})`
                                : ""}
                              {value.type === 2 ? "หักค่าบริการ" : ""}
                            </td>
                            <td>
                              {value.type === 2 ? "-" : ""}฿{value.amount}
                            </td>
                            <td>
                              {moment(value.created_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <br />
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div>
        <div className="row mt-3">
          <a href="coupon.php" className="btn btn-light ml-auto mr-2">
            ยกเลิก
          </a>
          <a href="coupon.php" type="submit" className="btn btn-primary mr-2">
            บันทึกข้อมูล
          </a>
        </div>
      </div> */}
    </div>
  );
};
